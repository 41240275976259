import {
  useInsuranceBankIdMandate,
  useInsuranceBsMandate,
  useInsuranceCardMandate,
  useInsuranceCriiptoMandate,
  useInsuranceKidMandate,
} from '@axo/shared/data-access/hooks';
import type { InsurancePaymentMandateType } from '../../config/payment-mandate.types';

/***/

type UseInsurancePaymentMandateApiImpl =
  | typeof useInsuranceBankIdMandate
  | typeof useInsuranceBsMandate // TODO verify
  | typeof useInsuranceCardMandate
  | typeof useInsuranceKidMandate
  | typeof useInsuranceCriiptoMandate;

/**
 * Factory for different InsurancePaymentMandateType implementations
 *
 * used by `useCreateInsurancePaymentMandate` to handle different `paymentMandateType`s
 */

export const useInsurancePaymentMandateApiImpl = (
  paymentMandateType: InsurancePaymentMandateType = 'card'
) => {
  const implMap: Record<
    InsurancePaymentMandateType,
    UseInsurancePaymentMandateApiImpl
  > = {
    bankid: useInsuranceBankIdMandate,
    bs: useInsuranceBsMandate,
    card: useInsuranceCardMandate,
    kid: useInsuranceKidMandate,
    criipto: useInsuranceCriiptoMandate,
  };

  return implMap[paymentMandateType];
};
