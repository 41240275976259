import { clsx } from 'clsx';
import {
  HTMLAttributes,
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Stack } from '../layout/item';
import { ModalHeader } from './Header';
import { ModalSubtitle, ModalTitle } from './Header/ModalHeader';

import styles from './modal.module.scss';

type ModalProps = {
  children?: ReactNode;
  className?: string;
  open?: boolean;
  onClose?: () => void;
  hasClose?: boolean;
} & HTMLAttributes<HTMLDialogElement>;

const addClosingAnimationListeners = (
  element: HTMLDialogElement | null,
  handler: () => void,
) => {
  element?.addEventListener('animationend', handler);
  element?.setAttribute('closing', '');
};

const removeClosingAnimationListeners = (
  element: HTMLDialogElement | null,
  handler: () => void,
) => {
  element?.removeEventListener('animationend', handler);
  element?.removeAttribute('closing');
};

/**
 * Modal component
 */
export const ModalRoot = ({
  children,
  className,
  open = false,
  onClose,
  hasClose = false,
  ...props
}: ModalProps): ReactElement => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialogElement = dialogRef.current;

    if (open) {
      dialogElement?.showModal();

      document.addEventListener('keydown', handleKeyDown);
    } else if (dialogElement?.open) {
      addClosingAnimationListeners(dialogElement, handleCloseAnimationEnd);
    }

    return () => {
      removeClosingAnimationListeners(dialogElement, handleOpenAnimationEnd);
      removeClosingAnimationListeners(dialogElement, handleCloseAnimationEnd);

      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, onClose]);

  const handleOpenAnimationEnd = () => {
    removeClosingAnimationListeners(dialogRef.current, handleOpenAnimationEnd);
  };

  const handleCloseAnimationEnd = () => {
    dialogRef.current?.close();
    removeClosingAnimationListeners(dialogRef.current, handleOpenAnimationEnd);
  };

  const handleClose = () => {
    onClose?.();
  };

  const handleDialogClick = (event: MouseEvent<HTMLElement>) => {
    if (!hasClose && event.target === dialogRef.current) {
      handleClose();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    event.key === 'Escape' && handleClose();
  };

  return (
    <dialog
      ref={dialogRef}
      className={clsx(styles.modal, className)}
      onClick={handleDialogClick}
      {...props}
    >
      {hasClose && (
        <Button
          className={styles.closeButton}
          variant={'ghost'}
          iconOnly
          size={'small'}
          aria-label={'close'}
          onClick={handleClose}
        >
          <Icon name={'x'} />
        </Button>
      )}
      <div className={styles.modal__content}>{children}</div>
    </dialog>
  );
};

type ModalActionsProps = {
  children?: ReactNode;
  className?: string;
} & HTMLDataAttributes;

const ModalActions = ({ children, className, ...props }: ModalActionsProps) => {
  return (
    <Stack
      as={'footer'}
      className={clsx(styles.modal__actions, className)}
      direction={'h'}
      {...props}
    >
      {children}
    </Stack>
  );
};

export const Modal = Object.assign(ModalRoot, {
  Header: ModalHeader,
  Title: ModalTitle,
  Subtitle: ModalSubtitle,

  Actions: ModalActions,
});
