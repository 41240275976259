import type { TProvidersProps } from '@axo/shared/auth/providers';
import {
  AuthenticationProviders,
  AuthenticationProvidersContextProvider,
} from '@axo/shared/auth/providers';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Skeleton } from '@axo/ui-core/components/Skeleton';
import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigService } from '../../../../config/ConfigService';
import { namespace } from '../../../../config/i18n.config';
import type {
  PaymentProviderImpl,
  PaymentProviderProps,
} from '../../PaymentProvider.types';
import styles from './criiptoPaymentProvider.module.scss';
import { useCriiptoPaymentProvider } from './useCriiptoPaymentProvider';

export const CriiptoPaymentProvider: PaymentProviderImpl = ({
  data,
  ...callbacks
}: PaymentProviderProps = {}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'steps.paymentMandate',
  });

  const { onSuccess, onError } = useCriiptoPaymentProvider({
    data,
    ...callbacks,
  });

  const authenticationProviders: ComponentProps<
    typeof AuthenticationProviders
  >['providers'] = {
    Criipto: {
      country: ConfigService.config.country as NonNullable<
        TProvidersProps['Criipto']
      >['country'],
      label: t('sign'),
    },
  };

  return (
    <Stack className={styles.criiptoPaymentProvider} data-content-state="idle">
      <Skeleton className={styles.skeleton} />

      <AuthenticationProvidersContextProvider
        onProviderLoginError={onError}
        onProviderLoginSuccess={onSuccess}
      >
        <AuthenticationProviders
          axoApiUrl={ConfigService.config.services.apiUrl}
          providers={authenticationProviders}
        />
      </AuthenticationProvidersContextProvider>
    </Stack>
  );
};
