import { Stack } from '@axo/ui-core/components/layout/item';
import { useMemo } from 'react';
import { ConfigService } from '../../../config/ConfigService';
import { AccountNumberForm } from './AccountNumberForm';
import { SignInsuranceForm } from './SignInsuranceForm';
import { SignInsuranceInformation } from './SignInsuranceInformation';
import styles from './signScreen.module.scss';

export const SignScreen = () => {
  const hasAccountNumberForm = useMemo(
    () => !!ConfigService.config.options?.hasAccountNumberForm || false,
    []
  );

  const isSignForm = useMemo(
    () => !!ConfigService.config.options?.hasSignInsuranceForm || false,
    []
  );

  return (
    <Stack className={styles.signScreen}>
      {hasAccountNumberForm && <AccountNumberForm />}
      {isSignForm ? <SignInsuranceForm /> : <SignInsuranceInformation />}
    </Stack>
  );
};
