import { IAcceptQuote } from '@axo/mypage/data-access';
import { Modal } from '@axo/ui-core/components/Modal';
import { breadcrumbs } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  KnownResourceType,
  useEventLogger,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { MarketCountryCode } from '@axo/shared/types';
import { Bank } from '@axo/ui-feature/components/Partners';
import { BankCode } from '@axo/ui-feature/components/Partners/Bank';
import { Country } from '@axo/ui-core/i18n/@types/Country.types';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Heading, Text } from '@axo/ui-core/components/typography';
import { useCallback, useEffect, useMemo } from 'react';
import styles from './ChangeAcceptOfferModal.module.scss';

type ChangeAcceptOfferModalProps = {
  oldOffer: loan_quote_presentation.EnrichedLoanQuote;
  newOffer: loan_quote_presentation.PresentedLoanQuote;
  acceptableQuoteIndex: number;
  onChangeOffer: (
    offer: loan_quote_presentation.PresentedLoanQuote,
    acceptableIndex: number
  ) => Promise<void>;
  marketCountry: Country;
  closeModal: () => void;
  accept: IAcceptQuote;
};

export function ChangeAcceptOfferModal({
  oldOffer,
  newOffer,
  acceptableQuoteIndex,
  marketCountry,
  accept,
  closeModal,
  onChangeOffer,
}: ChangeAcceptOfferModalProps) {
  const { t } = useTranslation();
  const oldLender = oldOffer.Lender;
  const newLender = newOffer.Lender;
  const log = useEventLogger(
    ...breadcrumbs(newOffer),
    ...breadcrumbs(oldOffer)
  );

  useEventResource(KnownResourceType.Lender, newOffer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, newOffer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, newOffer.ID);
  useEventResource(KnownResourceType.Lender, oldOffer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, oldOffer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, oldOffer.ID);

  useEffect(() => {
    log(EventCode.OpenChangeOfferModal, { newQuoteID: newOffer.ID });
  }, [log, newOffer, oldOffer]);

  const handleAccept = useCallback(async () => {
    await log(EventCode.ChangeOffer, { newQuoteID: newOffer.ID });
    await onChangeOffer(newOffer, acceptableQuoteIndex);
  }, [acceptableQuoteIndex, log, newOffer, onChangeOffer]);

  const onClose = useCallback(() => {
    log(EventCode.CloseChangeOfferModal, { newQuoteID: newOffer.ID });
    closeModal();
  }, [closeModal, log, newOffer]);

  const isButtonHeaderVisible = useMemo(
    () => marketCountry === MarketCountryCode.Sweden,
    [marketCountry]
  );

  return (
    <Modal
      open={!!oldOffer && !!newOffer}
      className={styles.modalRoot}
      data-modal-type={'info'}
    >
      <Modal.Header>
        <Modal.Title>
          <Heading size="s">{t('Change your selection')}</Heading>
        </Modal.Title>
      </Modal.Header>

      <Stack gap={'xs'} className={styles.content}>
        <Text>{t('You are changing your loan offer')}</Text>

        <Stack direction={'h'} className={styles.lenders}>
          <Stack gap={'3xs'} className={styles.lenderLogoContainer}>
            {oldLender && (
              <Bank
                name={oldLender.Name}
                code={oldLender.ShortName as BankCode}
                country={marketCountry}
              />
            )}
            <Text>
              <b>{t('Currently selected')}</b>
            </Text>
          </Stack>
          <Icon name="arrow-fat-line-right-solid" />
          <Stack gap={'3xs'} className={styles.lenderLogoContainer}>
            {newLender && (
              <Bank
                name={newLender.Name}
                code={newLender.ShortName as BankCode}
                country={marketCountry}
              />
            )}
            <Text>
              <b>{t('New selection')}</b>
            </Text>
          </Stack>
        </Stack>
      </Stack>

      <Modal.Actions className={styles.actions}>
        {isButtonHeaderVisible && (
          <Text>{t('Are you sure you want to change your selection?')}</Text>
        )}

        <Stack gap={'3xs'} direction={'h'}>
          <Button variant={'tertiary'} size={'small'} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            size={'small'}
            onClick={handleAccept}
            loading={accept.isLoading}
            disabled={accept.isLoading}
          >
            {t('Confirm and proceed')}
          </Button>
        </Stack>
      </Modal.Actions>
    </Modal>
  );
}
