import { insurance } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export type CreateInsurancePolicyParams = {
  EligibilityID: string;
  Source: string;
};

export const createInsurancePolicy = ({ url: { service }, token }: IAPI) => {
  return async function getInsurancePolicy(
    params: CreateInsurancePolicyParams
  ) {
    const url = new URL('/insurance/v2/policy', service);

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(params),
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance.InsurancePolicy;
  };
};
