export function interpolateUri(
  template: string,
  params?: Record<string, string | number>
): string {
  if (!params) return template;

  return template.replace(/:([a-zA-Z]+)/g, (_, key) => {
    if (params[key] === undefined) {
      throw new Error(`Missing parameter: ${key}`);
    }
    return encodeURIComponent(params[key].toString());
  });
}
