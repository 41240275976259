import type { ProductConfig } from './product-config.types';

export const productConfig: ProductConfig = {
  name: 'insurance.no',
  i18n: {
    locales: ['en-GB', 'nb-NO'],
    defaultLocale: 'nb-NO',
  },
  country: 'NO',
  currency: 'NOK',
  paymentMandateType: 'criipto',
  policy: {
    defaultValue: 8000,
    price: [
      { payout: 2000, premium: 129 },
      { payout: 3000, premium: 169 },
      { payout: 4000, premium: 209 },
      { payout: 5000, premium: 279 },
      { payout: 6000, premium: 319 },
      { payout: 7000, premium: 359 },
      { payout: 8000, premium: 409 },
      { payout: 9000, premium: 449 },
      { payout: 10000, premium: 499 },
    ],
  },
  options: {
    hasSignInsuranceForm: false,
    itemsOnBuyStepExpandedByDefault: ['documents'],
  },
};
