import { useEffect } from 'react';
import { useDataStore } from '../../store/useDataStore';
import { useViewStateMachine } from '../../store/useViewStateMachine';
import { defaultSteps } from '../../store/view-state-machine.config';
import type { ViewStep } from '../../store/view-state-machine.types';
import type { UseUserAnalytics } from './useUserAnalytics';
import { useUserAnalytics } from './useUserAnalytics';

const initializeViewAnalyticsSubscribers = (
  userAnalytics: UseUserAnalytics
) => {
  const {
    trackInitiated,
    trackStepViewed,
    trackCompleted,
    trackPayment,
    trackEligible,
    trackDeclined,
    trackAmount,
  } = userAnalytics;

  const unsubscribeInitiated = useViewStateMachine.subscribe(
    (state) => state.isLoading,
    (isLoading) => {
      if (!isLoading) trackInitiated();
    }
  );

  const unsubscribeProgress = useViewStateMachine.subscribe(
    (state) => state.currentStep,
    (currentStep) => {
      const currentStepName: ViewStep = defaultSteps[currentStep];
      const amount = useViewStateMachine.getState().formData.insuredAmount;

      switch (currentStepName) {
        case 'INTRO':
        case 'BUY':
        case 'SIGN':
          // track interaction with amount from previous step
          if (amountTrackedSteps.has(currentStep - 1))
            trackAmount(amount, defaultSteps[currentStep - 1].toLowerCase());
          trackStepViewed(currentStep, currentStepName.toLowerCase());
          break;
        case 'PAYMENT_AUTHORIZE':
          trackStepViewed(currentStep, currentStepName.toLowerCase());
          break;
        case 'CONFIRMATION':
          trackStepViewed(currentStep, currentStepName.toLowerCase());
          trackCompleted(amount);
          break;
      }
    }
  );

  const unsubscribeEligibility = useDataStore.subscribe(
    (state) => state.application.isEligible,
    (isEligible) => {
      if (typeof isEligible === 'boolean') trackEligible(isEligible);
    }
  );

  const unsubscribeDeclined = useViewStateMachine.subscribe(
    (state) => state.isEnabled,

    (isEnabled) => {
      if (isEnabled === false) {
        const currentStep = useViewStateMachine.getState().currentStep;
        trackDeclined(defaultSteps[currentStep].toLowerCase());
      }
    }
  );

  const amountTrackedSteps = new Set<number>();
  let amountInitializedDefaults = false;

  const unsubscribeAmountChanges = useViewStateMachine.subscribe(
    (state) => state.formData.insuredAmount,
    (amount) => {
      const currentStep = useViewStateMachine.getState().currentStep;
      const currentStepName: ViewStep = defaultSteps[currentStep];
      if (!amountTrackedSteps.has(currentStep) && amountInitializedDefaults) {
        amountTrackedSteps.add(currentStep);

        // track the initial interaction
        if (currentStep === 0)
          trackAmount(amount, currentStepName.toLowerCase());
      }
      amountInitializedDefaults = true;
    }
  );

  const unsubscribePaymentInitiated = useViewStateMachine.subscribe(
    (state) => state.shouldStartPaymentProcess,
    (shouldStartPaymentMandate) => {
      // if payment mandate provider start is triggered, then `shouldStartPaymentMandate` is set to false
      if (!shouldStartPaymentMandate) trackPayment('Initiated');
    }
  );

  const unsubscribePaymentCompleted = useViewStateMachine.subscribe(
    (state) => state.formData.hasPaymentMandate,
    (hasPaymentMandate) => {
      if (hasPaymentMandate) trackPayment('Completed');
    }
  );

  const unsubscribePaymentFailure = useViewStateMachine.subscribe(
    (state) => state.formData.paymentProviderError,

    (paymentProviderError) => {
      if (paymentProviderError !== null) {
        const e =
          paymentProviderError === 'error'
            ? 'Failed'
            : paymentProviderError === 'canceled'
            ? 'Cancelled'
            : undefined;
        if (e) trackPayment(e);
      }
    }
  );

  return () => {
    unsubscribeInitiated();
    unsubscribeProgress();
    unsubscribeEligibility();
    unsubscribeDeclined();
    unsubscribeAmountChanges();
    unsubscribePaymentInitiated();
    unsubscribePaymentCompleted();
    unsubscribePaymentFailure();
  };
};

export const useInitializeViewAnalyticsSubscribers = () => {
  const userAnalytics = useUserAnalytics();

  useEffect(() => {
    const cleanup = initializeViewAnalyticsSubscribers(userAnalytics);
    return cleanup;
  }, []);
};
