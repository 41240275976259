import { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { TProgressBarWithDescriptionProps } from '@axo/ui-feature/components/ProgressBarWithDescription/ProgressBarWithDescription';
import {
  type CustomerEventType,
  HumanReadableID,
  LenderID,
} from '@axo/shared/data-access/types/ws_connection';
import { LoanRequestWSSubscriber } from './LoanRequestWSSubscriber';

// FIXME don't rely on a view component props for state definition
type ProgressData = Exclude<TProgressBarWithDescriptionProps, 'className'> & {
  currentStep: number;
  amountOfOffers: number;
  offersUnlocked?: boolean;
  allBanksResponded?: boolean;
};
type EventData = {
  humanReadableID: HumanReadableID;
  name: CustomerEventType;
  lenderID?: LenderID;
};
// FIXME offers is misleading, it's a loan quote request
type OffersData = {
  total: number;
  completed: number;
};
type State = ProgressData & {
  applicationEvent?: EventData;
  offers: OffersData;
  isInProgress: boolean;
};

type Action =
  | { type: 'Reset progress state' }
  | { type: 'Set progress data'; payload: ProgressData } // FIXME in use ?
  | { type: 'Set progress event'; payload: EventData } // FIXME dispatched by WebSocketSubscriber, but none consumes it
  | { type: 'Set progress offers'; payload: OffersData }
  | { type: 'Set progress view'; payload: boolean };

export const initialProgressState: State = {
  title: '',
  description: '',
  state: 'neutral',
  progress: 0,
  offers: {
    total: 0,
    completed: 0,
  },
  currentStep: 0,
  amountOfOffers: 0,
  isInProgress: true,
};

export const ApplicationProgressContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialProgressState,
  dispatch: () => undefined,
});

const reducer = (state: State, action: Action): State => {
  let progress;

  switch (action.type) {
    case 'Reset progress state':
      return { ...state, progress: initialProgressState.progress };
    case 'Set progress data':
      return { ...state, ...action.payload };
    case 'Set progress event':
      return { ...state, applicationEvent: action.payload };
    case 'Set progress offers': // FIXME should it calculate the progress ??
      progress = (action.payload.completed / state.offers.total) * 100; // FIXME should work with percentage
      return { ...state, offers: action.payload, progress };
    case 'Set progress view':
      return { ...state, isInProgress: action.payload };
    default:
      return state;
  }
};

export const ApplicationProgressProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialProgressState);
  return (
    <ApplicationProgressContext.Provider value={{ state, dispatch }}>
      <LoanRequestWSSubscriber />
      {children}
    </ApplicationProgressContext.Provider>
  );
};
