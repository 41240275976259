import { useViewStateMachine } from './store/useViewStateMachine';
import { ViewStateMachine } from './store/view-state-machine.types';

type UseContentLayout = Pick<
  ViewStateMachine,
  'proceed' | 'focus' | 'isMainView'
>;

export const useContentLayout = (): UseContentLayout => {
  const proceed = useViewStateMachine((state) => state.proceed);
  const focus = useViewStateMachine((state) => state.focus);
  const isMainView = useViewStateMachine((state) => state.isMainView);

  return { proceed, focus, isMainView };
};
