import { getInsurancePersonIsEligibleV2 } from '@axo/shared/data-access/api/insurance';
import { useAPI } from '@axo/shared/data-access/provider';
import { insurance } from '@axo/shared/data-access/types';
import { useMutation } from 'react-query';

export const useInsurancePersonIsEligible = () => {
  const api = useAPI();

  return useMutation((params: insurance.InsurancePersonIsEligibleV2Params) =>
    getInsurancePersonIsEligibleV2(api)(params)
  );
};
