import type { AccountNumberLabels, Term } from './content.types';
import type { en } from './en';

export const nb: Partial<typeof en> = {
  product: {
    name: 'Axo utgiftsforsikring',
  },
  amountSelector: {
    intro:
      'Vil du legge til en utgiftsforsikring før du går videre? En utgiftsforsikring kan være en sikkerhet for deg om det skulle oppstå endringer i livet ditt, enten det er knyttet til din arbeidssituasjon eller helse.',
    labels: {
      currency: 'kr',
      month: 'mnd',
      premium: 'Månedlig kostnad',
      payout: 'Betales i tilfelle skade',
      payoutInfo:
        'Du kan få erstatning fra det øyeblikket forsikringen trer i kraft og karenstiden er over',
    },
  },
  navigation: {
    intro: 'Ønsker du å tegne en forsikring?',
    next: {
      INTRO: 'Vis mer informasjon om forsikringen',
      BUY: 'Kjøp forsikring og signer med BankId',
      SIGN: 'Signer med BankId',
      PAYMENT_AUTHORIZE: 'Signering',
    },
    skip: 'Nei takk, jeg ønsker ikke å forsikre meg',
    disable: 'Nei takk.',
    enable: 'Les mer om forsikringen',
  },
  steps: {
    intro: {
      usps: [
        'Månedlig utbetaling på <b>{{insuredAmount}}/mnd</b> ved arbeidsledighet og/eller langtidssykdom i opptil 12 måneder',
        'Pengene utbetales direkte til din konto, og du kan bruke dem som du vil',
        'Du kan når som helst si opp forsikringen',
        'Engangserstatning <b>50 000 kr</b> ved dødsfall som følge av sykdom eller ulykke',
      ],
    },
    buy: {
      faqs: [
        {
          title: 'Hvordan vet jeg om forsikringen passer for mine behov?',
          body:
            '<b>Forbrukernes krav og behov</b><br />' +
            '<p>Hovedelementet i forsikringen er en månedlig erstatning ved ufrivillig arbeidsledighet. Forsikringen er derfor primært utformet for å møte kravene og behovene til forbrukere som kan ha vanskeligheter med å dekke sine utgifter, eller som har behov for større økonomisk trygghet ved ufrivillig arbeidsledighet.</p>' +
            '<p>Vi anbefaler at du går gjennom følgende fire trinn for å vurdere om forsikringen vil passe for deg:</p>' +
            '<ol>' +
            '<li>Lag et budsjett for å få oversikt over hvilke månedlige utgifter du vil komme til å ha, dersom du skulle bli ufrivillig arbeidsledig.</li>' +
            '<li>Vurder hvilken annen inntekt du vil kunne ha i denne situasjonen (f.eks. dagpenger fra NAV og/eller andre forsikringer, sykepenger, oppsparte midler, etc.), og om du vil være komfortabel med nivået på din totale inntekt i en slik situasjon.</li>' +
            "<li>Dersom svaret på spørsmålet i punkt 2 er 'nei', bør du vurdere om du ønsker å kjøpe forsikring som gir deg mulighet til å øke din totale månedsinntekt i inntil 12 måneder ved ufrivillig arbeidsledighet.</li>" +
            '<li>Dersom du har kommet til at forsikring vil være fordelaktig, vil budsjettet kunne hjelpe deg med å velge et passende forsikringsbeløp per måned. Husk at erstatningen som utbetales fra forsikringen ved ufrivillig arbeidsledighet maksimalt kan utgjøre 60 prosent av din gjennomsnittlige netto månedslønn i de tre månedene rett før oppsigelsen.</li>' +
            '</ol>' +
            '<p>Forsikringen dekker også dødsfall som følge av sykdom eller ulykke og erstatter boet med et engangsbeløp på NOK 50 000 som skal brukes til f.eks. begravelsesutgifter.</p>',
        },
        {
          key: 'documents',
          title: 'Forsikringsfordeler',
          body: '<p>Du bestemmer selv hva du bruker kompensasjonen til, for eksempel husleie, lånekostnader, oppvarming eller mat.</p><p>Forsikringen din følger deg, ikke lånet ditt. Det betyr at forsikringen din forblir gyldig selv om du betaler tilbake lånet eller bytter låneleverandør.</p>',
          links: [
            {
              label: 'Produktinformasjon',
              href: `/insurance.no/docs/InsuranceExpenses.pdf`,
              icon: 'file-pdf-solid',
            },
            {
              label: 'Forhåndsinformasjon om forsikring',
              href: `/insurance.no/docs/AdvanceInformation.pdf`,
              download:
                'Forhåndsinformasjon-Axo_Finans_Utgiftsforsikring_2024-03-18.pdf',
              icon: 'file-pdf-solid',
            },
          ],
        },
      ],
      salesTerms: {
        intro: 'Kontroller følgende',
        terms: <Term[]>[
          {
            name: 'general',
            label:
              'Jeg oppfyller vilkårene nevnt ovenfor og bekrefter at jeg har lest og akseptert <a href="/insurance.no/docs/TermsAndConditions.pdf" download="Vilkår og betingelser.pdf">forhåndsinformasjon og fullstendige forsikringsvilkår</a>.',
            sub: [
              {
                name: 'age',
                label: 'er i alderen 18-65 år',
              },
              {
                name: 'employment',
                label: 'Jeg bor permanent i Norge og tilhører norsk folketrygd',
              },
              {
                name: 'health',
                label: 'Jeg er fast ansatt og jobber minimum 16 timer i uken',
              },
              {
                name: 'coverage',
                label:
                  'Jeg kjenner ikke til sykdom eller kroniske lidelser som kan føre til sykefravær og forstår at forsikringen kun gjelder ulykker eller sykdommer som inntreffer i forsikringsperiodne',
              },
            ],
          },
          {
            name: 'loan',
            label:
              'Jeg forstår at utgiftsforsikringen er <strong>gyldig uansett om jeg får innvilget lån eller ikke</strong>.',
          },
        ],
      },
    },
    sign: {
      accountNumber: {
        title: 'Kontodetaljer',
        description:
          'Ettersom du har valgt Trygghetsforsikring, vil forsikringspremien bli trukket månedlig fra denne kontoen via avtalegiro.',
        note: 'Kontonummeret må være en transaksjonskonto (ikke en sparekonto) og tilhøre deg personlig.',
        form: <AccountNumberLabels>{
          clearingNumber: {
            name: 'clearingNumber',
            label: 'Clearingnummer',
            placeholder: '1100',
            validation: {
              required: 'Dette feltet er obligatorisk',
              invalid:
                'Clearingnumre må kun inneholde sifre og være nøyaktig 4 tegn lange, og må være mellom 1100 og 9969',
            },
          },
          accountNumber: {
            name: 'kontonummer',
            label: 'Kontonummer',
            placeholder: '0123456789',
            validation: {
              required: 'Dette feltet er obligatorisk',
              invalid: 'Kontonummeret må være mellom 7 og 10 siffer langt',
            },
          },
        },
      },
      signInsurance: {
        intro: 'Kjøp av AXO Finans utgiftsforsikring',
        term: <Term>{
          name: 'sign',
          label:
            'Betaling: Du vil motta faktura for din forsikring på e-faktura hvis du har godtkjent e-faktura i nettbanken din. Hvis du ikke har satt opp e-faktura vil du motta fakturaene på e-post sendt fra vår betalingspartner Payex.',
        },
        note: '',
      },
    },
    paymentMandate: {
      title: '',
      message: {
        warn: 'Vi kunne ikke fullføre forsikringsbetalingen din fordi du avbestilte. Prøv igjen hvis du vil fortsette.',
        error:
          'Vi kunne ikke fullføre forsikringsbetalingen din på grunn av en teknisk feil. Vennligst prøv igjen.',
      },
      sign: 'Signer med BankID',
    },
    confirmation: {
      title: 'Gratulerer med den nye utgiftsforsikringen!',
      description:
        'Du vil få tilsendt en e-post med informasjon om forsikringen din innen 24 timer. Hvis du har spørsmål, er du velkommen til å kontakte oss.',
    },
  },
};
