import { clsx } from 'clsx';
import { ComponentProps, ReactElement, ReactNode } from 'react';
import { feedbackStateIconsMap } from '../../../models/feedback-state-icons.const';
import {
  FeedbackState,
  feedbackState,
} from '../../../models/feedback-state.const';
import { Icon } from '../../Icon';
import { Stack } from '../../layout/item';
import { Heading, Text } from '../../typography';

import styles from './modalHeader.module.scss';

export const modalFeedbackState = [...feedbackState, 'none'] as const;

type ModalHeaderProps = {
  children?: ReactNode;
  className?: string;
  feedbackState?: (typeof modalFeedbackState)[number];
} & HTMLDataAttributes;

/**
 * ModalHeader component
 */
export const ModalHeader = ({
  children,
  className,
  feedbackState = 'none',
  ...props
}: ModalHeaderProps): ReactElement => (
  <Stack
    as={'header'}
    gap={'3xs'}
    className={clsx(styles.modal__header, className)}
    data-feedback-state={feedbackState}
    {...props}
  >
    {feedbackState !== 'none' && (
      <div className={styles.icon}>
        <Icon
          name={feedbackStateIconsMap[feedbackState as FeedbackState]}
          size={'m'}
        />
      </div>
    )}
    <Stack as={'span'} gap={'xs'}>
      {children}
    </Stack>
  </Stack>
);

export const ModalTitle = ({
  children,
  className,
  size = 's',
  ...props
}: ModalHeaderProps & {
  size?: ComponentProps<typeof Heading>['size'];
}): ReactElement => (
  <Heading
    size={size}
    as={'h3'}
    className={clsx(styles.modalTitle, className)}
    {...props}
  >
    {children}
  </Heading>
);

export const ModalSubtitle = ({
  children,
  className,
  ...props
}: ModalHeaderProps): ReactElement => (
  <Text
    size={'s'}
    as={'p'}
    className={clsx(styles.modalSubtitle, className)}
    {...props}
  >
    {children}
  </Text>
);
