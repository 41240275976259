import { insurance } from '@axo/shared/data-access/types';
import { Status } from '@axo/shared/data-access/types/insurance_request';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInsurancePersonIsEligible as useInsurancePersonIsEligibleV1 } from './useInsurancePersonIsEligible.v1';
import { useInsurancePersonIsEligible as useInsurancePersonIsEligibleV2 } from './useInsurancePersonIsEligible.v2';

export type InsurancePersonIsEligibleParams =
  | {
      apiVersion: 'v1';
      params: insurance.InsurancePersonIsEligibleParams;
    }
  | {
      apiVersion: 'v2';
      params: insurance.InsurancePersonIsEligibleV2Params;
    };

export function useInsurancePersonIsEligible({
  apiVersion,
  params,
}: InsurancePersonIsEligibleParams) {
  const [isEligible, setIsEligible] = useState<boolean | undefined>(undefined);
  const [eligibilityID, setEligibilityID] = useState<string | undefined>(
    undefined
  );
  const isEligibilityRequested = useRef<boolean>(false);

  const { data } = useInsurancePersonIsEligibleV1({
    ...(params as insurance.InsurancePersonIsEligibleParams),
    skip: apiVersion === 'v2',
  });

  const { mutate } = useInsurancePersonIsEligibleV2();

  useEffect(() => {
    if (isEligible !== undefined || isEligibilityRequested.current) return;

    if (apiVersion === 'v2') {
      isEligibilityRequested.current = true;

      mutate(params, {
        onSuccess: (data) => {
          const _isEligible = data?.Status === Status.Approved;

          setIsEligible(_isEligible);

          if (_isEligible) setEligibilityID(data.ID);

          if (!_isEligible)
            console.log('Not eligible for insurance. Reason:', data.DenyReason);
        },
      });
    }
  }, [apiVersion, params, mutate, isEligible]);

  useEffect(() => {
    if (apiVersion === 'v1' && data) {
      setIsEligible(data.isEligible);
    }
  }, [apiVersion, data]);

  return useMemo(
    () => ({ isEligible, eligibilityID }),
    [isEligible, eligibilityID]
  );
}
