import { insurance } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export function getInsurancePersonIsEligibleV2({
  url: { service },
  token,
}: IAPI) {
  return async function getInsurancePersonIsEligible(
    params: insurance.InsurancePersonIsEligibleV2Params
  ) {
    const url = new URL(`/insurance/v2/eligibility`, service);

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(params),
    });

    const responseJson = await response.json();

    if (!response.ok)
      throw new Error(`Network response was not OK: ${response.status}`);

    return (await responseJson) as insurance.InsurancePersonIsEligibleV2;
  };
}
