import { useEffect, useState } from 'react';
import { ConfigService } from './config/ConfigService';
import type { ProductName } from './config/products.config';
import { useInitializeViewAnalyticsSubscribers } from './services/analytics/view-analytics.subscribers';
import { useInitializeApiService } from './services/api/useInitializeApiService';
import { useInitializeDataStoreSubscribers } from './store/data-store.subscribers';
import type { ApplicationData, AuthData } from './store/data-store.types';
import { useDataStore } from './store/useDataStore';
import { useViewStateMachine } from './store/useViewStateMachine';
import { useInitializeViewStateMachineSubscribers } from './store/view-state-machine.subscribers';

export type InitializeAppProps = {
  product: ProductName;
  /**
   * @params state : 2 supported options
   *  1. (recommended) `state.auth.token` and `state.application.loan.ID`
   *  2. `state.auth.magicToken`
   */
  state?: {
    auth?: Partial<AuthData>;
    application?: Partial<ApplicationData>;
  };
};

export const useInitializeApp = (props: InitializeAppProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const initialState = props.state || {};

  ConfigService.initialize(props.product);

  const { setSteps } = useViewStateMachine();
  const { setMagicToken, setToken, setLoan, setAppUri } = useDataStore();
  useInitializeApiService();
  useInitializeViewStateMachineSubscribers();
  useInitializeDataStoreSubscribers();
  useInitializeViewAnalyticsSubscribers();

  useEffect(() => {
    if (isInitialized) return;

    if (ConfigService.config.steps) setSteps(ConfigService.config.steps);

    const { auth, application } = initialState;
    const { magicToken, token } = auth || {};
    const { loan } = application || {};

    if (!(token && loan?.ID) && !magicToken) {
      throw new Error(
        'Initialization requires either: `auth.token` and `application.loan.ID`, or `auth.magicToken`.'
      );
    }

    if (magicToken) setMagicToken(magicToken);
    if (token) setToken(token);
    if (loan) setLoan(loan);

    const appUri = window.location.href; // TODO
    setAppUri(appUri);

    setIsInitialized(true);
  }, [isInitialized, initialState, setMagicToken, setToken]);

  return {
    isInitialized,
  };
};
