import { insurance } from '@axo/shared/data-access/types';
import { createResourceRequest, getResourceRequest } from '../api-crud.factory';

export const createCriiptoMandate = createResourceRequest<
  insurance.CreateCriiptoMandateDto,
  insurance.CriiptoMandate
>('/insurance/v2/mandate/criipto');

export const getCriiptoMandate = getResourceRequest<
  insurance.CriiptoMandate,
  {
    id: string;
  }
>('/insurance/v2/mandate/criipto/:id');
