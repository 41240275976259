import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './button.module.scss';

type ButtonSetProps = {
  children: ReactNode;
  direction?: 'horizontal' | 'vertical';
  spacing?: 'small' | 'medium';
  justify?: 'start' | 'end' | 'center' | 'space-between';
  className?: string;
};

/**
 *
 * @note use `ButtonGrid` for a responsive version
 */
export const ButtonSet = ({
  children,
  direction = 'horizontal',
  spacing = 'medium',
  justify = 'start',
  className,
}: ButtonSetProps) => (
  <div
    className={clsx(styles.buttonSet, className)}
    data-direction={direction}
    data-spacing={spacing}
    data-justify={justify}
  >
    {children}
  </div>
);
