import { clsx } from 'clsx';
import { ReactElement } from 'react';
import { SkeletonImage } from '../SkeletonImage/SkeletonImage';
import { BankCode } from './banks.types';
import { Country } from '@axo/ui-core/i18n/@types/Country.types';

import styles from './bank.module.scss';

type BankProps = {
  code: BankCode;
  country: Country;
  name?: string;
  isAlt?: boolean;
  className?: string;
} & HTMLDataAttributes;

/**
 * Bank component
 */
export const Bank = ({
  code = 'BN',
  country = 'SE',
  name,
  isAlt,
  className,
  ...props
}: BankProps): ReactElement => {
  return (
    <div className={clsx(styles.bank, className)} {...props}>
      <SkeletonImage
        src={`/partners/bank/${country.toLowerCase()}/${code}${
          isAlt ? '-alt' : ''
        }.svg`}
        alt={`${name ?? code}`}
        className={styles.image}
      />
    </div>
  );
};
