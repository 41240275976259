import { Accordion } from '@axo/ui-core/components/Accordion';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigService } from '../../../config/ConfigService';
import { namespace } from '../../../config/i18n.config';
import type { FAQ } from '../../../locales/content.types';
import styles from './buyScreen.module.scss';
import { SalesTermsForm } from './SalesTermsForm';

export const BuyScreen = () => {
  const { t } = useTranslation(namespace, { keyPrefix: 'steps.buy' });
  const itemsExtendedByDefault =
    ConfigService.config.options?.itemsOnBuyStepExpandedByDefault ?? [];

  const faqs = t('faqs', {
    returnObjects: true,
  }) as FAQ[];

  const extendedByDefault = useMemo(
    () =>
      faqs
        .filter((faq) => faq.key && itemsExtendedByDefault.includes(faq.key))
        ?.map((faq) => faq.title) || [],
    [faqs, itemsExtendedByDefault]
  );

  return (
    <Stack className={styles.buyScreen}>
      <Accordion
        type="multiple"
        className={styles.faqs}
        defaultValue={extendedByDefault}
      >
        {faqs.map((item, index) => (
          <Accordion.Item
            className={styles.faqItem}
            key={index}
            value={item.title}
            header={<Text size="m">{item.title}</Text>}
            styleVariant="faq"
          >
            {item.body ? (
              <Text size="s" dangerouslySetInnerHTML={{ __html: item.body }} />
            ) : null}
            {item.links
              ? item.links.map((link, lIndex) => (
                  <a
                    className={styles.faqLink}
                    key={lIndex}
                    href={link.href}
                    download={link.download ?? undefined}
                  >
                    {link.icon ? <Icon name={link.icon} size="s" /> : null}
                    <>{link.label}</>
                  </a>
                ))
              : null}
          </Accordion.Item>
        ))}
      </Accordion>

      <SalesTermsForm />
    </Stack>
  );
};
