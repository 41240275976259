import { create } from 'zustand';
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from 'zustand/middleware';

import type {
  ApplicationData,
  DataStore,
  DataStoreState,
  RequestState,
} from './data-store.types';

const initialRequestState: RequestState = {
  data: null,
  status: 'idle',
  error: null,
};

const initialState: DataStoreState = {
  auth: {
    token: null,
    magicToken: null,
  },
  application: {
    loan: null,
    insurance: { ...initialRequestState },
    insuranceRequest: { ...initialRequestState },
    insurancePaymentMandate: { ...initialRequestState },
    insurancePaymentMandateComplete: { ...initialRequestState },
    isEligible: null,
    input: null,
  },
  appUri: null,
};

const name = 'product-insurance/data-store';

const useDataStore = create<DataStore>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set) => ({
          ...initialState,

          setMagicToken: (magicToken: string) =>
            set(
              (state) => ({ auth: { ...state.auth, magicToken } }),
              undefined,
              'data/setMagicToken'
            ),
          setToken: (token: string) =>
            set(
              (state) => ({ auth: { ...state.auth, token } }),
              undefined,
              'data/setToken'
            ),
          setLoan: (loan: any) =>
            set(
              (state) => ({ application: { ...state.application, loan } }),
              undefined,
              'data/setLoan'
            ),
          setInsurance: (insurance: any) =>
            set(
              (state) => ({ application: { ...state.application, insurance } }),
              undefined,
              'data/setInsurance'
            ),
          setRequestState: (
            key: keyof ApplicationData,
            state: Partial<RequestState>
          ) =>
            set(
              (existingState) => ({
                application: {
                  ...existingState.application,
                  [key]: {
                    ...(existingState.application[key] as RequestState),
                    ...state,
                  },
                },
              }),
              undefined,
              `data/set${key.charAt(0).toUpperCase() + key.slice(1)}`
            ),
          setIsEligible: (isEligible: boolean) =>
            set(
              (state) => ({
                application: { ...state.application, isEligible },
              }),
              undefined,
              'data/setIsEligible'
            ),
          setAppUri: (appUri: string) =>
            set((state) => ({ appUri }), undefined, 'data/setAppUri'),
          setInput: (input: Partial<ApplicationData['input']> | null) =>
            set(
              (state) => ({
                application: {
                  ...state.application,
                  input: input
                    ? { ...state.application.input, ...input }
                    : null,
                },
              }),
              undefined,
              'data/setInput'
            ),
        }),
        {
          name,
          storage: createJSONStorage(() => sessionStorage),
        }
      ),
      {
        name,
      }
    )
  )
);

export { useDataStore };
