import { ViewSequence, ViewSequenceConfig } from './view-state-machine.types';

const staticViewSequence: ViewSequence = [
  {
    header: { visible: true, focus: true },
    progress: { visible: true },
    quotes: { visible: true },
    insurance: { visible: true },
    footer: { visible: true },
  },
];

const insuranceMandatoryViewSequence: ViewSequence = [
  {
    header: { visible: false },
    progress: { visible: false },
    quotes: { visible: false },
    insurance: { visible: true, focus: true },
    footer: { visible: false },
  },
  {
    header: { visible: true, focus: true },
    progress: { visible: true },
    quotes: { visible: true },
    insurance: { visible: true },
    footer: { visible: true },
  },
];

export const config: ViewSequenceConfig = {
  static: staticViewSequence,
  'insurance-mandatory': insuranceMandatoryViewSequence,
};
