export enum LoanQuoteEventType {
  LoanQuoteRegistered = 'loan-quote-registered',
  LoanQuoteApproved = 'loan-quote-approved',
  LoanQuoteNotApproved = 'loan-quote-not-approved',
  LoanQuoteCancelled = 'loan-quote-cancelled',
}

export enum StepEventType {
  StepsInitiated = 'steps-initiated',
  StepAdvanced = 'step-advanced',
  StepsCompleted = 'steps-completed',
}

export type CustomerEventType = LoanQuoteEventType | StepEventType;

export type HumanReadableID = string;
export type LenderID = string;

export interface CustomerLoanQuoteWSData {
  Event: LoanQuoteEventType;
  ApplicationHumanReadableID: HumanReadableID;
  LenderID: LenderID;
}

export interface CustomerStepWSData {
  Event: StepEventType;
  ApplicationHumanReadableID: HumanReadableID;
}

export type CustomerWSData = CustomerLoanQuoteWSData | CustomerStepWSData;

export const isCustomerLoanQuoteWSData = (
  data: CustomerWSData
): data is CustomerLoanQuoteWSData =>
  Object.values(LoanQuoteEventType).includes(data.Event as LoanQuoteEventType);

export const isCustomerStepWSData = (
  data: CustomerWSData
): data is CustomerStepWSData =>
  Object.values(StepEventType).includes(data.Event as StepEventType);
