import React, { useState } from 'react';
import clsx from 'clsx';
import { Skeleton } from '@axo/ui-core/components/Skeleton';
import styles from './skeletonImage.module.scss';

type SkeletonImageProps = {
  src: string;
  alt: string;
  className?: string;
};

/**
 * SkeletonImage component
 *
 * shows skeleton till image is loaded, and falls back to a label (instead of broken image link)
 */

export const SkeletonImage = ({ src, alt, className }: SkeletonImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <div className={clsx(styles.skeletonImage, className)}>
      {!isLoaded && <Skeleton />}
      {isError ? (
        <div className={styles.label}>{alt}</div>
      ) : (
        <img
          src={src}
          alt={alt}
          data-is-loading={!isLoaded ? '' : undefined}
          onLoad={() => setIsLoaded(true)}
          onError={() => {
            setIsLoaded(true);
            setIsError(true);
          }}
        />
      )}
    </div>
  );
};
