import { applicationKeys } from '@axo/shared/data-access/hooks';
import {
  type CustomerWSData,
  isCustomerLoanQuoteWSData,
  isCustomerStepWSData,
  LoanQuoteEventType,
  StepEventType,
} from '@axo/shared/data-access/types/ws_connection';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import {
  IWebSocketData,
  useWSSubscriber,
} from '@axo/shared/feature/web-socket';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { ApplicationProgressContext } from './ApplicationProgressProvider';

// FIXME this is the only reason why there were updates on the progress, we should not need this mechanism, but fix the root cause
// Set 30 seconds as recovery timeout
const RECOVERY_TIMEOUT = 1000 * 30;
const onRecoveryTimout = (callback: () => void) =>
  setInterval(callback, RECOVERY_TIMEOUT);

export function LoanRequestWSSubscriber() {
  const client = useQueryClient();

  const {
    state: { application },
  } = useContext(LoanApplicationContext);
  const {
    state: { offers, progress, allBanksResponded },
    dispatch,
  } = useContext(ApplicationProgressContext);

  const isPollingDisabled = allBanksResponded || progress === 100;

  if (application?.ID === '') throw new Error('Missing applicationID');

  const applicationKey = useMemo(
    () =>
      applicationKeys.root({
        applicationID: application?.ID,
      }),
    [application]
  );

  const invalidateQueries = () => {
    client.invalidateQueries(applicationKey);
  };

  const recoveryIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isPollingDisabled) {
      recoveryIntervalRef.current = onRecoveryTimout(invalidateQueries);
    }

    return () => {
      if (recoveryIntervalRef.current) {
        clearInterval(recoveryIntervalRef.current);
        recoveryIntervalRef.current = null;
      }
    };
  }, [isPollingDisabled]);

  const handleWebSocketMessage = (data: IWebSocketData) => {
    const messageData = data.latestMessage as unknown as CustomerWSData;

    // Restart recovery timout
    if (recoveryIntervalRef.current) {
      clearInterval(recoveryIntervalRef.current);
      if (!isPollingDisabled) {
        recoveryIntervalRef.current = onRecoveryTimout(invalidateQueries);
      }
    }

    // FIXME not sure what the purpose of this one is...
    dispatch({
      type: 'Set progress event',
      payload: {
        name: messageData.Event,
        humanReadableID: messageData.ApplicationHumanReadableID,
        lenderID: isCustomerLoanQuoteWSData(messageData)
          ? messageData.LenderID
          : undefined,
      },
    });

    // TODO skipping temporary, assuming it's only 1 application active atm.
    // if (
    //   application?.HumanReadableID === messageData.ApplicationHumanReadableID
    // ) {
    // TODO 1 tick / lender
    if (
      offers.completed < offers.total &&
      isCustomerLoanQuoteWSData(messageData) &&
      [
        LoanQuoteEventType.LoanQuoteApproved,
        LoanQuoteEventType.LoanQuoteNotApproved,
      ].includes(messageData.Event)
    ) {
      dispatch({
        type: 'Set progress offers',
        payload: {
          ...offers,
          completed: offers.completed + 1,
        },
      });
    }

    if (
      (isCustomerLoanQuoteWSData(messageData) &&
        [
          LoanQuoteEventType.LoanQuoteApproved,
          // LoanQuoteEventType.LoanQuoteNotApproved,
          LoanQuoteEventType.LoanQuoteCancelled,
        ].includes(messageData.Event)) ||
      (isCustomerStepWSData(messageData) &&
        [StepEventType.StepAdvanced, StepEventType.StepsCompleted].includes(
          messageData.Event
        ))
    ) {
      invalidateQueries();
    }
    // }
  };

  const subscriptions = [
    {
      source: LoanQuoteEventType.LoanQuoteRegistered,
      code: LoanQuoteEventType.LoanQuoteRegistered,
      subscriberCallback: handleWebSocketMessage,
    },
    {
      source: LoanQuoteEventType.LoanQuoteApproved,
      code: LoanQuoteEventType.LoanQuoteApproved,
      subscriberCallback: handleWebSocketMessage,
    },
    {
      source: LoanQuoteEventType.LoanQuoteNotApproved,
      code: LoanQuoteEventType.LoanQuoteNotApproved,
      subscriberCallback: handleWebSocketMessage,
    },
    {
      source: LoanQuoteEventType.LoanQuoteCancelled,
      code: LoanQuoteEventType.LoanQuoteCancelled,
      subscriberCallback: handleWebSocketMessage,
    },
    {
      source: StepEventType.StepAdvanced,
      code: StepEventType.StepAdvanced,
      subscriberCallback: handleWebSocketMessage,
    },
    {
      source: StepEventType.StepsCompleted,
      code: StepEventType.StepsCompleted,
      subscriberCallback: handleWebSocketMessage,
    },
  ];

  useWSSubscriber(subscriptions);

  return null;
}
