import {
  useInsurancePersonIsEligible,
  usePerson,
} from '@axo/shared/data-access/hooks';
import { useEffect, useMemo } from 'react';
import { useDataStore } from '../../store/useDataStore';

type UseIsEligible = {
  apiVersion: 'v1' | 'v2';
};

export const useIsEligible = ({ apiVersion }: UseIsEligible) => {
  const { application, setIsEligible, setEligibilityID } = useDataStore();
  const isEligible = useDataStore((state) => state.application.isEligible);

  const { data: personData } = usePerson(
    isEligible === null ? application.loan?.PersonID || '' : ''
  );

  const useInsurancePersonIsEligibleParams = useMemo(() => {
    if (apiVersion === 'v1') {
      return {
        apiVersion,
        params: {
          PersonID: application.loan?.PersonID || '',
          PersonAge: personData?.Age?.toString() || '',
          EmploymentStatus: application.loan?.EmploymentStatus || '',
          MarketCountry: application.loan?.MarketCountry || '',
        },
      };
    }

    return {
      apiVersion,
      params: {
        PersonID: application.loan?.PersonID || '',
        CustomerID: application.loan?.CustomerID || '',
        ClientID: '',
        LoanApplicationID: application.loan?.ID || '',
        Source: 'mypage',
        FirstName: personData?.FirstName || '',
        LastName: personData?.LastName || '',
        MarketCountry: application.loan?.MarketCountry || '',
      },
    };
  }, [apiVersion, application, personData]);

  const { eligibilityID, isEligible: _isEligible } =
    useInsurancePersonIsEligible(useInsurancePersonIsEligibleParams);

  useEffect(() => {
    if (_isEligible === undefined) return;

    setIsEligible(_isEligible);

    if (eligibilityID) {
      setEligibilityID(eligibilityID);
    }
  }, [eligibilityID, _isEligible]);
};
