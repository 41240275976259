export interface IInsuranceMandateKeyParams {
  insuranceMandateID: string;
}

export const insuranceMandateKeys = {
  root: (params: IInsuranceMandateKeyParams) =>
    ['insurance mandate', params.insuranceMandateID] as const,
};

export function parseInsuranceMandateKey<
  T extends keyof typeof insuranceMandateKeys
>(
  queryKey: ReturnType<(typeof insuranceMandateKeys)[T]>
): IInsuranceMandateKeyParams {
  const [, insuranceMandateID] = queryKey;

  return { insuranceMandateID };
}
