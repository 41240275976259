import { useCallback, useEffect } from 'react';
import type { InsurancePaymentMandateType } from '../../config/payment-mandate.types';
import { getUpdateRequestState } from '../../store/data-store.utils';
import { useDataStore } from '../../store/useDataStore';
import {
  createPaymentMandateCompleteDto,
  isInsurancePaymentMandateCompleteDataComplete,
  useCreatePaymentMandateCompleteApiImpl,
} from './useCreateInsurancePaymentMandateCompleteApi.factory';
import { useInsurancePaymentMandateApiImpl } from './useInsurancePaymentMandateApi.factory';

type UseCreateInsurancePaymentMandateCompleteProps = {
  paymentMandateType: InsurancePaymentMandateType;
};

/**
 * finalize payment mandate w/ external provider data
 * `create` → `get` latest version of `insurancePaymentMandate`
 */

export const useCreateInsurancePaymentMandateComplete = ({
  paymentMandateType,
}: UseCreateInsurancePaymentMandateCompleteProps) => {
  const {
    insurancePaymentMandate,
    insurancePaymentMandateComplete,
    insurance,
    input,
  } = useDataStore((state) => state.application);
  const authToken = useDataStore((state) => state.auth.token);
  const { updateRequestState } = getUpdateRequestState(
    'insurancePaymentMandateComplete'
  );

  /** create */

  const useCreateInsurancePaymentMandateCompleteApi =
    useCreatePaymentMandateCompleteApiImpl(paymentMandateType);

  const {
    mutateAsync: createInsurancePaymentMandateComplete,
    data: createInsurancePaymentMandateCompleteData,
    ...createInsurancePaymentMandateCompleteResult
  } = useCreateInsurancePaymentMandateCompleteApi({
    token: input?.paymentMandateProviderResponse?.token || '',
  });

  const createPaymentMandateComplete = useCallback(async () => {
    updateRequestState({ status: 'loading' });

    const paymentMandateDto = createPaymentMandateCompleteDto(
      paymentMandateType,
      {
        MandateID: insurancePaymentMandate.data?.ID,
        PolicyID: insurance.data?.ID,
        MarketCountry: insurance.data?.MarketCountry,
        ...input,
      }
    );

    createInsurancePaymentMandateComplete(paymentMandateDto as never);
  }, [
    insurancePaymentMandate.data?.ID,
    insurance.data?.ID,
    insurance.data?.MarketCountry,
    input,
  ]);

  useEffect(() => {
    // await auth.token availability when redirected from external provider
    if (insurancePaymentMandateComplete.status === 'requested' && !!authToken)
      createPaymentMandateComplete();
  }, [insurancePaymentMandateComplete.status, authToken]);

  /** get */

  const useInsurancePaymentMandateApi =
    useInsurancePaymentMandateApiImpl(paymentMandateType);

  const {
    data: insurancePaymentMandateData,
    ...insurancePaymentMandateResult
  } = useInsurancePaymentMandateApi(insurancePaymentMandate.data?.ID, false);

  useEffect(() => {
    if (
      insurancePaymentMandateData &&
      isInsurancePaymentMandateCompleteDataComplete(
        paymentMandateType,
        insurancePaymentMandateData
      )
    )
      updateRequestState({
        status: 'completed',
        data: insurancePaymentMandateData,
      });

    if (insurancePaymentMandateComplete.data) {
      updateRequestState({
        status: 'completed',
        data: insurancePaymentMandateComplete.data,
      });
    }
  }, [
    insurancePaymentMandateResult.isSuccess,
    insurancePaymentMandateData,
    insurancePaymentMandateComplete.data,
  ]);
};
