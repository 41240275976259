import { insurance_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import type { CreateCriiptoMandateDto } from '@axo/shared/data-access/types/insurance';
import { useMemo } from 'react';
import { useMutation } from 'react-query';

export function useCreateInsuranceCriiptoMandate({ token }: { token: string }) {
  const api = useAPI();

  /**
   * Sometimes useAPI's token is not available.
   * To avoid this, we can use the token from the parameter.
   */
  const _api = useMemo(
    () => ({
      ...api,
      token: api.token || token,
    }),
    [api, token]
  );

  return useMutation((data: CreateCriiptoMandateDto) => {
    if (!_api.token) {
      throw new Error('API token is not available or initialized.');
    }

    return insurance_api.createCriiptoMandate(_api)(data);
  });
}
