import { create } from 'zustand';
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from 'zustand/middleware';
import { config } from './view-state-machine.config';

import {
  ViewArea,
  ViewSequenceConfigType,
  ViewStateMachine,
  ViewStateMachineState,
} from './view-state-machine.types';

const initialState: ViewStateMachineState = {
  currentSequenceStep: 0,
  currentFocusArea: null,
  sequence: null,
  areas: null,
  isMainView: true,
};

const useViewStateMachine = create<ViewStateMachine>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set, get) => ({
          ...initialState,

          setSequence: (type: ViewSequenceConfigType) =>
            set(
              (state) => {
                // Skip if sequence was already set during rehydration
                if (state.sequence !== null) return state;

                return {
                  sequence: config[type],
                  currentSequenceStep: 0,
                  areas: config[type][0] || null,
                  isMainView: config[type].length === 1,
                };
              },
              undefined,
              'view/setSequence'
            ),

          proceed: () =>
            set(
              (state) => {
                if (!state.sequence) return state;

                const nextStep = Math.min(
                  state.currentSequenceStep + 1,
                  state.sequence.length - 1
                );
                return {
                  currentSequenceStep: nextStep,
                  isMainView: nextStep === state.sequence.length - 1,
                  areas: state.sequence[nextStep],
                };
              },
              undefined,
              'view/proceed'
            ),

          focus: (area: ViewArea | null) =>
            set(
              {
                currentFocusArea: area,
              },
              undefined,
              'view/focus'
            ),
        }),
        {
          name: 'content-layout/view-state-machine',
          storage: createJSONStorage(() => sessionStorage),
        }
      ),
      {
        name: 'content-layout/view-state-machine',
      }
    )
  )
);

export { useViewStateMachine };
