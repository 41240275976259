import { create } from 'zustand';
import {
  devtools,
  subscribeWithSelector,
  persist,
  createJSONStorage,
} from 'zustand/middleware';

import { defaultSteps } from './view-state-machine.config';
import { isStepValid } from './view-state-machine.guards';

import type {
  FormData,
  ViewStateMachine,
  ViewStateMachineState,
  ViewStep,
} from './view-state-machine.types';

const initialFormData: FormData = {
  insuredAmount: 0,
  acceptedTerms: false,
  hasBankAccount: false,
  hasSigned: false,
  hasPaymentMandate: false,
  paymentProviderError: null,
};

const initialState: ViewStateMachineState = {
  currentStep: 0,
  isFirstStep: true,
  isLastStep: false,
  isPaymentMandateStep: false,
  shouldStartPaymentProcess: true,
  isStepValid: true,
  isEnabled: true,
  isLoading: true,
  steps: defaultSteps,
  formData: initialFormData,
  isStandAlone: false,
};

const name = 'product-insurance/view-state-machine';

const useViewStateMachine = create<ViewStateMachine>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set, get) => ({
          ...initialState,

          goToNextStep: () =>
            set(
              (state) => {
                // retry payment provider if error
                if (
                  state.isPaymentMandateStep &&
                  state.formData.paymentProviderError
                ) {
                  return {
                    shouldStartPaymentProcess: true,
                  };
                }

                const nextStep = Math.min(
                  state.currentStep + 1,
                  state.steps.length - 1
                );
                const newState = {
                  currentStep: nextStep,
                  isFirstStep: nextStep === 0,
                  isLastStep: nextStep === state.steps.length - 1,
                  isPaymentMandateStep:
                    'PAYMENT_AUTHORIZE' === state.steps[nextStep],
                };
                return {
                  ...newState,
                  isStepValid: isStepValid({ ...state, ...newState }),
                };
              },
              undefined,
              'view/goToNextStep'
            ),

          goToPreviousStep: () =>
            set(
              (state) => {
                const prevStep = Math.max(state.currentStep - 1, 0);
                const newState = {
                  currentStep: prevStep,
                  isFirstStep: prevStep === 0,
                  isLastStep: prevStep === state.steps.length - 1,
                  isPaymentMandateStep:
                    'PAYMENT_AUTHORIZE' === state.steps[prevStep],
                };

                return {
                  ...newState,
                  isStepValid: isStepValid({ ...state, ...newState }),
                };
              },
              undefined,
              'view/goToPreviousStep'
            ),

          toggleEnabled: () =>
            set(
              (state) => {
                const newState = { isEnabled: !state.isEnabled };
                return {
                  ...newState,
                  isStepValid: isStepValid({ ...state, ...newState }),
                };
              },
              undefined,
              'view/toggleEnabled'
            ),

          setIsLoading: (loading: boolean) =>
            set(
              (state) => ({ isLoading: loading }),
              undefined,
              'view/setIsLoading'
            ),

          startPaymentProcess: (start: boolean) =>
            set(
              (state) => ({ shouldStartPaymentProcess: start }),
              undefined,
              'view/startPaymentProcess'
            ),

          updateFormData: (newData: Partial<FormData>) =>
            set(
              (state) => {
                const newState = {
                  formData: {
                    ...state.formData,
                    ...newData,
                  },
                };
                return {
                  ...newState,
                  isStepValid: isStepValid({ ...state, ...newState }),
                };
              },
              undefined,
              'view/updateFormData'
            ),

          setSteps: (steps: ViewStep[]) =>
            set({ steps }, undefined, 'view/setSteps'),

          setIsStandAlone: (isStandAlone: boolean) =>
            set({ isStandAlone }, undefined, 'view/setIsStandAlone'),
        }),
        {
          name,
          storage: createJSONStorage(() => sessionStorage),
        }
      ),
      {
        name,
      }
    )
  )
);

export { useViewStateMachine };
