import { getInsurancePersonIsEligible } from '@axo/shared/data-access/api/insurance';
import { useAPI } from '@axo/shared/data-access/provider';
import { insurance } from '@axo/shared/data-access/types';
import { useQuery } from 'react-query';
import { insurancePersonIsEligibleKeys } from '../cache-keys/insurancePersonIsEligibleKeys';
import { queryFunction } from '../query-function';

export function useInsurancePersonIsEligible(
  params: insurance.InsurancePersonIsEligibleParams & { skip?: boolean }
) {
  const api = useAPI();
  const cacheKey = insurancePersonIsEligibleKeys.root(params);

  return useQuery<insurance.InsurancePersonIsEligible>(
    cacheKey,
    queryFunction(getInsurancePersonIsEligible(api), ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return queryParams as insurance.InsurancePersonIsEligibleParams;
    }),
    {
      enabled:
        !params.skip &&
        !!params.PersonID &&
        !!params.PersonAge &&
        !!params.EmploymentStatus &&
        !!params.MarketCountry,
    }
  );
}
