import {
  useInsurancePersonIsEligible,
  usePerson,
} from '@axo/shared/data-access/hooks';
import { useEffect } from 'react';
import { useDataStore } from '../../store/useDataStore';

export const useIsEligible = () => {
  const { application, setIsEligible } = useDataStore();
  const isEligible = useDataStore((state) => state.application.isEligible);

  const { data: personData } = usePerson(
    isEligible === null ? application.loan?.PersonID || '' : ''
  );

  const { data } = useInsurancePersonIsEligible({
    PersonID: application.loan?.PersonID || '',
    PersonAge: personData?.Age?.toString() || '',
    EmploymentStatus: application.loan?.EmploymentStatus || '',
    MarketCountry: application.loan?.MarketCountry || '',
  });

  useEffect(() => {
    if (data) {
      setIsEligible(data.isEligible);
    }
  }, [data]);
};
