import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../../config/i18n.config';
import type { Term } from '../../../locales/content.types';

import styles from './signInsuranceForm.module.scss';

export const SignInsuranceInformation = () => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'steps.sign.signInsurance',
  });

  const signTerm = t('term', {
    returnObjects: true,
  }) as Term;

  return (
    <Stack className={styles.signInsuranceForm}>
      <Text size="m">
        <b>{t('intro')}</b>
      </Text>
      <span dangerouslySetInnerHTML={{ __html: signTerm.label }} />
      <Text size="s" className={styles.note}>
        {t('note')}
      </Text>
    </Stack>
  );
};
