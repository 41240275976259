import { Icon } from '@axo/ui-core/components/Icon';
import { Slider } from '@axo/ui-core/components/input/Slider';
import { Tooltip } from '@axo/ui-core/components/Tooltip';
import { Heading, Text } from '@axo/ui-core/components/typography';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigService } from '../../config/ConfigService';
import { namespace } from '../../config/i18n.config';
import { useViewStateMachine } from '../../store/useViewStateMachine';
import styles from './amountSelector.module.scss';

type AmountSelectorProps = {
  isInteractive: boolean;
  isVisible: boolean;
};

export const AmountSelector = ({
  isInteractive = true,
  isVisible = true,
}: AmountSelectorProps) => {
  const { t } = useTranslation(namespace, { keyPrefix: 'amountSelector' });
  const { insuredAmount } = useViewStateMachine((state) => state.formData);
  const updateFormData = useViewStateMachine((state) => state.updateFormData);

  const {
    policy: { price, defaultValue },
    currency,
    i18n: { defaultLocale },
  } = ConfigService.config;

  const Currency = new Intl.NumberFormat(defaultLocale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
  });

  const payouts = price.map((item) => item.payout);
  const minPayout = Math.min(...payouts);
  const maxPayout = Math.max(...payouts);
  const step = payouts[1] - payouts[0];

  const insuredRange = { min: minPayout, max: maxPayout, step };
  const insuredDefaultValue =
    insuredAmount || defaultValue || payouts[Math.floor(payouts.length * 0.4)];

  const premiumAmount = useMemo(
    () => price.find((item) => item.payout === insuredAmount)?.premium ?? 0,
    [price, insuredAmount]
  );

  useEffect(() => {
    updateFormData({ insuredAmount: insuredDefaultValue });
  }, [insuredDefaultValue]);

  const onValueChange = (value: number[]) =>
    updateFormData({ insuredAmount: value[0] });

  return (
    <div
      className={styles.amountSelector}
      data-is-interactive={isInteractive ? '' : undefined}
      data-is-visible={isVisible ? '' : undefined}
    >
      <Text className={styles.intro} size={'m'}>
        {t('intro')}
      </Text>
      <div className={styles.details}>
        <div>
          <Heading size={'xxs'} className={styles.value}>
            {`${Currency.format(premiumAmount)}/${t('labels.month')}`}
          </Heading>
          <Text size={'xs'} className={styles.label}>
            {t('labels.premium')}
          </Text>
        </div>
        <div>
          <Heading size={'xxs'} className={styles.value}>
            {`${Currency.format(insuredAmount)}/${t('labels.month')}`}
          </Heading>
          <Text size={'xs'} className={styles.label}>
            {t('labels.payout')}
          </Text>
        </div>
        <div className={styles.info}>
          <Tooltip content={t('labels.payoutInfo')} position={'left'}>
            <Icon name={'info-solid'} size={'xs'} />
          </Tooltip>
        </div>
      </div>
      <div className={styles.input}>
        <Slider
          onValueChange={onValueChange}
          {...insuredRange}
          defaultValue={[insuredDefaultValue]}
        />
      </div>
    </div>
  );
};
