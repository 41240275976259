import {
  useCreateInsuranceBankIdMandateFinalize,
  useCreateInsuranceCriiptoMandate,
} from '@axo/shared/data-access/hooks';
import type {
  CreateCriiptoMandateDto,
  CriiptoMandate,
} from '@axo/shared/data-access/types/insurance';
import type {
  BankIdPaymentMandate,
  CreateBankIdPaymentMandateFinalizeRequest,
} from '@axo/shared/data-access/types/insurance_payment';
import type { InsurancePaymentMandateType } from '../../config/payment-mandate.types';

/***/

type UseCreateInsurancePaymentMandateCompleteApiImpl =
  | typeof useCreateInsuranceBankIdMandateFinalize
  | typeof useCreateInsuranceCriiptoMandate;
// TODO Add other implementations

/**
 * Factory for different PaymentMandateType implementations
 *
 * used by `useCreateInsurancePaymentMandateComplete` to handle different `paymentMandateType`s
 *
 * @note `kid` is a query, `card` `bs` and `bankid` is a mutation
 * (aka. ask back-end to switch `kid` to a mutation to streamline this)
 * – also, be consistent in finalize w/ payload, vs patch update and finalize – call it complete ?
 */

export const useCreatePaymentMandateCompleteApiImpl = (
  paymentMandateType: InsurancePaymentMandateType = 'card'
) => {
  const implMap: Record<
    InsurancePaymentMandateType,
    UseCreateInsurancePaymentMandateCompleteApiImpl | null
  > = {
    bankid: useCreateInsuranceBankIdMandateFinalize,
    bs: null,
    card: null,
    kid: null,
    criipto: useCreateInsuranceCriiptoMandate,
  };

  if (!implMap[paymentMandateType]) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return implMap[
    paymentMandateType
  ] as UseCreateInsurancePaymentMandateCompleteApiImpl;
};

/**
 * Helper
 */

type PaymentMandateDto =
  | CreateBankIdPaymentMandateFinalizeRequest
  | CreateCriiptoMandateDto;

type DtoCreator = (data: CreateInputData) => PaymentMandateDto;

type CreateInputData = {
  MandateID: string;
  PolicyID: string;
  amount: number;
  MarketCountry: string;
  //
  paymentMandateProviderResponse: Record<string, any>;
};

const createBankIdDto = (
  data: CreateInputData
): CreateBankIdPaymentMandateFinalizeRequest => ({
  MandateID: data.MandateID,
  PolicyID: data.PolicyID,
  Amount: data.amount,
  AssentlyToken: data.paymentMandateProviderResponse.token,
});

const createCriiptoDto = (data: CreateInputData): CreateCriiptoMandateDto => ({
  PolicyID: data.PolicyID,
  Amount: data.amount,
  PersonID: data.paymentMandateProviderResponse.personID,
  MarketCountry: data.MarketCountry,
});

const dtoCreators: Record<InsurancePaymentMandateType, DtoCreator | null> = {
  bankid: createBankIdDto,
  bs: null,
  card: null,
  kid: null,
  criipto: createCriiptoDto,
};

export const createPaymentMandateCompleteDto = (
  paymentMandateType: InsurancePaymentMandateType = 'card',
  data: any
) => {
  const dtoCreator = dtoCreators[paymentMandateType];

  if (!dtoCreator) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return dtoCreator(data);
};

/**
 * Helper
 */

const isBankIdDataComplete = (data: BankIdPaymentMandate): boolean => {
  return data?.Status === 'completed';
};

const isCriiptoDataComplete = (data: CriiptoMandate): boolean => {
  return !!data?.PersonID && !!data?.PolicyID;
};

const dataValidators: Record<
  InsurancePaymentMandateType,
  ((data: any) => boolean) | null
> = {
  bankid: isBankIdDataComplete,
  bs: null,
  card: null,
  kid: null,
  criipto: isCriiptoDataComplete,
};

export const isInsurancePaymentMandateCompleteDataComplete = (
  paymentMandateType: InsurancePaymentMandateType,
  data: any
): boolean => {
  const validator = dataValidators[paymentMandateType];

  if (!validator) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return validator(data);
};
