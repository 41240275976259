import { useEffect } from 'react';
import { getUpdateRequestState } from './data-store.utils';
import { useDataStore } from './useDataStore';
import { useViewStateMachine } from './useViewStateMachine';
import { defaultSteps } from './view-state-machine.config';
import type { ViewStep } from './view-state-machine.types';

const initializeSubscribers = () => {
  const { updateRequestState: updateInsuranceRequestState } =
    getUpdateRequestState('insuranceRequest');

  const unsubscribeCurrentStep = useViewStateMachine.subscribe(
    (state) => state.currentStep,
    (currentStep) => {
      const currentStepName: ViewStep = defaultSteps[currentStep];

      switch (currentStepName) {
        case 'INTRO':
          //
          break;
        case 'BUY':
          //
          break;
        case 'SIGN':
          useDataStore.getState().setInput({
            amount: useViewStateMachine.getState().formData.insuredAmount,
          });
          updateInsuranceRequestState({ status: 'requested' });
          break;
        case 'PAYMENT_AUTHORIZE':
          break;
        case 'CONFIRMATION':
          break;
        default:
          break;
      }
    }
  );

  return () => {
    unsubscribeCurrentStep();
  };
};

export const useInitializeViewStateMachineSubscribers = () => {
  useEffect(() => {
    const cleanup = initializeSubscribers();
    return cleanup;
  }, []);
};
