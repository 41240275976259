import { insurance_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { useQuery } from 'react-query';
import {
  insuranceMandateKeys,
  parseInsuranceMandateKey,
} from '../cache-keys/insuranceMandateKeys';
import { queryFunction } from '../query-function';

export function useInsuranceCriiptoMandate(insuranceMandateID?: string) {
  const api = useAPI();
  const cacheKey = insuranceMandateKeys.root({
    insuranceMandateID: insuranceMandateID ?? '',
  });

  return useQuery(
    cacheKey,
    queryFunction(insurance_api.getCriiptoMandate(api), ({ queryKey }) => ({
      id: parseInsuranceMandateKey(queryKey).insuranceMandateID,
    })),
    {
      enabled: !!api.token && !!insuranceMandateID,
    }
  );
}
