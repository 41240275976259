import { useCallback } from 'react';

import { ConfigService } from '../../config/ConfigService';
import type { InsurancePaymentMandateType } from '../../config/payment-mandate.types';
import { useViewStateMachine } from '../../store/useViewStateMachine';
import type { PaymentProviderImpl } from './PaymentProvider.types';
import type { PaymentData } from './provider/bankid/bank-id-payment.types';
import { BankIdPaymentProvider } from './provider/bankid/BankIdPaymentProvider';
import { CriiptoPaymentProvider } from './provider/criipto/CriiptoPaymentProvider';

const providersConfig: Partial<
  Record<InsurancePaymentMandateType, PaymentProviderImpl>
> = {
  bankid: BankIdPaymentProvider,
  criipto: CriiptoPaymentProvider,
};

type Props = {
  /** for testing purpose in storybook */
  data?: PaymentData;
};

export const PaymentProvider = ({ data }: Props = {}) => {
  const { paymentMandateType } = ConfigService.config;
  const PaymentProviderImpl = providersConfig[paymentMandateType];

  const updateFormData = useViewStateMachine((state) => state.updateFormData);

  const onSuccess = useCallback(
    () => updateFormData({ paymentProviderError: null }),
    [paymentMandateType]
  );

  const onCancel = useCallback(
    () => updateFormData({ paymentProviderError: 'canceled' }),
    [paymentMandateType]
  );

  const onError = useCallback(
    () => updateFormData({ paymentProviderError: 'error' }),
    [paymentMandateType]
  );

  if (!PaymentProviderImpl)
    throw new Error(
      `PaymentProvider is missing an implementation for ${paymentMandateType}`
    );

  return (
    <PaymentProviderImpl
      onSuccess={onSuccess}
      onCancel={onCancel}
      onError={onError}
      data={data}
    />
  );
};
