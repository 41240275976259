import type { AccountNumberLabels, FAQ, Term } from './content.types';

export const en = {
  product: {
    name: 'Axo income insurance',
  },
  amountSelector: {
    intro: 'Select the amount you want to receive upon loss',
    labels: {
      currency: '€',
      month: 'month',
      premium: 'Monthly fee',
      payout: 'Payout upon loss',
      payoutInfo:
        'You can receive compensation from the time the insurance becomes effective and the waiting period is over',
    },
  },
  navigation: {
    intro: 'Do you want to buy insurance?',
    next: {
      INTRO: 'Sign up for insurance',
      BUY: 'Buy insurance',
      SIGN: 'Sign',
      PAYMENT_AUTHORIZE: 'Sign',
    },
    skip: 'No, go to quote',
    disable: 'No, thanks',
    enable: 'Read more about insurance',
  },
  steps: {
    intro: {
      usps: [
        'Compensation of 5,000 SEK/month for unemployment and long-term illness',
        'Contributes to expenses such as housing, food, fuel, loans',
        'Compensation for up to 12 months',
        'One-time compensation of 100,000 SEK for certain serious diseases',
      ],
    },
    buy: {
      faqs: <FAQ[]>[
        {
          title: 'How to choose the right amount',
          body: 'You can sum up the expenses you or your family have per month (e.g., housing, food, fuel, loans) in relation to the unemployment or sickness benefits you would receive.',
        },
        {
          title: 'Detailed documents',
          links: [
            {
              label: 'Product Information',
              href: 'https://3i0wz95rphq6.b-cdn.net/6uyej14xj0bc-villkor-axo-finans-trygghetsforsakring-v-20240417.pdf',
              download: 'villkor-axo-finans-trygghetsforsakring-v-20240417.pdf',
              icon: 'file-pdf-solid',
            },
            {
              label: 'Complete Insurance Terms',
              href: 'https://3i0wz95rphq6.b-cdn.net/768hlidon5sw-forkopsinformati-axo-finans-trygghetsforsakring-v-20240417.pdf',
              download:
                'forkopsinformati-axo-finans-trygghetsforsakring-v-20240417.pdf',
              icon: 'file-pdf-solid',
            },
          ],
        },
      ],
      salesTerms: {
        intro: 'Check the following',
        terms: <Term[]>[
          {
            name: 'general',
            label:
              'I fulfill the above requirements and confirm that I have read and accepted the <a href="https://3i0wz95rphq6.b-cdn.net/768hlidon5sw-forkopsinformati-axo-finans-trygghetsforsakring-v-20240417.pdf" download="forkopsinformati-axo-finans-trygghetsforsakring-v-20240417.pdf">Pre-purchase information and complete terms</a>.',
            sub: [
              {
                name: 'age',
                label:
                  'am 18-65 years old, registered in Sweden and covered by Swedish social insurance',
              },
              {
                name: 'employment',
                label:
                  'have permanent employment of at least 16 h/week, without knowledge of upcoming unemployment or termination',
              },
              {
                name: 'health',
                label:
                  'am fully able to work, without knowledge of serious illness or potential sick leave',
              },
              {
                name: 'coverage',
                label:
                  'am aware that the insurance only applies to unemployment, illness, or injury occurring during the insurance period',
              },
            ],
          },
          {
            name: 'loan',
            label:
              'I understand that the insurance applies regardless of whether my loan is approved or not.',
          },
        ],
      },
    },
    sign: {
      accountNumber: {
        title: 'Account Details',
        description:
          'Since you have chosen Security Insurance, the insurance premium will be deducted monthly from this account via direct debit.',
        note: 'The account number must be a transaction account (not a savings account) and must personally belong to you.',
        form: <AccountNumberLabels>{
          clearingNumber: {
            name: 'clearingNumber',
            label: 'Clearing Number',
            placeholder: '1100',
            validation: {
              required: 'This field is required',
              invalid:
                'Clearing number must contain only digits and be exactly 4 characters long, and must be between 1100 and 9969',
            },
          },
          accountNumber: {
            name: 'accountNumber',
            label: 'Account Number',
            placeholder: '0123456789',
            validation: {
              required: 'This field is required',
              invalid: 'Account number must be between 7 and 10 digits long',
            },
          },
        },
      },
      signInsurance: {
        intro: 'Sign',
        term: <Term>{
          name: 'sign',
          label:
            'I have read and approve the <a href="#">terms for direct debit and processing of personal data</a>.',
        },
        note: 'I confirm the purchase of my Security Insurance and direct debit approval by signing with BankID.',
      },
    },
    paymentMandate: {
      title: 'Sign the agreement for direct debit payments',
      message: {
        warn: 'We could not complete your insurance payment because you cancelled. Please try again if you want to go ahead.',
        error:
          'We could not complete your insurance payment due to a technical error. Please try again.',
      },
      sign: 'Sign with BankID',
    },
    confirmation: {
      title: 'Congratulations on your new insurance policy!',
      description:
        'An email with details about your insurance will be sent to you within 24 hours. If you have any questions, feel free to contact us.',
    },
  },
};
