import { MyPageFlags } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import { useApplicationStatusUtils } from '@axo/shared/data-access/hooks';
import {
  loan_application,
  loan_quote_presentation,
} from '@axo/shared/data-access/types';
import { LoadedLoanApplication } from '@axo/shared/feature/providers';
import { useFlags } from '@axo/shared/services/feature-flags';
import { TState } from '@axo/ui-feature/components/ProgressBarWithDescription/ProgressBarWithDescription';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ApplicationProgressContext,
  initialProgressState,
} from './ApplicationProgressProvider';

/** TODO refactor this state x ws subscriber x view logic with global zustand store */
export const useProgressBarWithDescription = ({
  application,
  quotes,
}: {
  application: LoadedLoanApplication | undefined;
  quotes: loan_quote_presentation.GetQuotesResponse | undefined;
}) => {
  const { state, dispatch } = useContext(ApplicationProgressContext);
  const { t } = useTranslation();
  const {
    'unlock-offers-on-progress-update': flag_unlock_offers_on_progress_update,
  } = useFlags(MyPageFlags);
  const { isInApplicationStatusList } = useApplicationStatusUtils();

  const status = useMemo(() => {
    return application?.Status as loan_application.ApplicationStatus;
  }, [application]);

  const [offersUnlocked, setOffersUnlocked] = useState(false);

  useEffect(() => {
    // Show progress bar for 3xx (excl. 305, 389, 390), 400, 410 statuses only
    const isInProgress = isInApplicationStatusList(
      status,
      // [270, 300, 310, 320, 370, 388, 400, 410] // original
      /**
       * for direct access, the progress bar should always be visible till final state
       * additions :
       * 180 Pending receipt. Fetching external data
       * 200 Completed
       * 210 Pending registration with banks
       * 305 Grooming
       */
      [180, 200, 210, 270, 300, 305, 310, 320, 370, 388, 400, 410]
    );

    dispatch({ type: 'Set progress view', payload: isInProgress });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    // Set offers state when updated Steps data received
    const offersResponse = (quotes?.Steps || []).reduce(
      (acc, step) => {
        acc.total += step.Total;
        acc.completed += step.Completed;
        return acc;
      },
      { total: 0, completed: 0 }
    );

    // it starts of with 0/0 or tiering can be in progress but the completed steps total is finished,
    // f.ex. 14/14, then another step is added so 14/17
    if (!quotes?.StepsCompleted) offersResponse.total += 1;

    dispatch({ type: 'Set progress offers', payload: offersResponse });
  }, [dispatch, quotes?.Steps]);

  const allBanksResponded =
    (!!state.offers.total && state.offers.total === state.offers.completed) ||
    quotes?.AllRegisteredLendersResponded;
  const currentStep =
    (quotes?.Steps && quotes.Steps.find((step) => step.Step === 0)
      ? quotes.CurrentStep + 1
      : quotes?.CurrentStep) ?? 0;
  const isUnlockStep =
    currentStep >= (flag_unlock_offers_on_progress_update ?? 2);
  const amountOfOffers =
    quotes?.AcceptableLoanQuotes.length ||
    quotes?.AcceptedLoanQuotes.length ||
    0;
  const hasOffers = Boolean(amountOfOffers);
  const isApproved = isInApplicationStatusList(status, [400, 410]);

  useEffect(() => {
    const getProgressTitle = () => {
      if (allBanksResponded) {
        return hasOffers
          ? t('All the banks have responded! You can now choose an offer')
          : t('All banks have responded');
      }
      if (isUnlockStep) {
        return '';
      }
      return hasOffers
        ? t('Awaiting response from banks')
        : initialProgressState.title;
    };

    const getProgressDescription = () => {
      if (isApproved) {
        if (allBanksResponded) return '';
        if (hasOffers)
          return t('Most banks have responded and you can now choose an offer');
      }
      if (allBanksResponded && !hasOffers) {
        return t('Unfortunately, none of our bank partners have made an offer');
      }
      return hasOffers
        ? t('We recommend that you wait until all the banks have responded')
        : t('Your application is being processed. Please wait');
    };

    const getProgressState = (): TState => {
      if (
        (allBanksResponded || isUnlockStep) &&
        (hasOffers || !!quotes?.CancelledLoanQuotes.length)
      ) {
        setOffersUnlocked(true);
        return 'success';
      }
      if (hasOffers) return 'warn';
      return initialProgressState.state;
    };

    const getProgressAmount = () => {
      if (allBanksResponded) return 100;
      if (state.offers.total > 0) {
        return Math.round((state.offers.completed / state.offers.total) * 100);
      }
      return initialProgressState.progress;
    };

    dispatch({
      type: 'Set progress data',
      payload: {
        title: getProgressTitle(),
        description: getProgressDescription(),
        state: getProgressState(),
        progress: getProgressAmount(),
        allBanksResponded,
        currentStep,
        offersUnlocked,
        amountOfOffers,
      },
    });
  }, [
    dispatch,
    flag_unlock_offers_on_progress_update,
    quotes,
    state.offers,
    isApproved,
  ]);

  // TODO: Need to trigger re-render locked buttons
  return { offersUnlocked };
};
