import { Modal } from '@axo/ui-core/components/Modal';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Heading, Text } from '@axo/ui-core/components/typography';
import styles from './ContactModal.module.scss';

import { i18n, Market } from './i18n';

export function ContactModal({
  isOpen,
  onClose,
  marketCountry,
}: {
  isOpen: boolean;
  onClose: () => void;

  // TODO: marketCountry is temporary prop. Remove when config is available.
  marketCountry: Market;
}) {
  return (
    <Modal
      open={isOpen}
      hasClose
      onClose={onClose}
      className={styles.contactModal}
    >
      <Modal.Header feedbackState={'info'}>
        <Modal.Title>
          <Heading size="s">
            {i18n[marketCountry].title}
          </Heading>
        </Modal.Title>
      </Modal.Header>
      <Stack gap="xs" className={styles.content}>
        <Text as="p">
          {i18n[marketCountry].text}
        </Text>
      </Stack>
      <Modal.Actions className={styles.footer}>
        <Button
          href={`tel:${i18n[marketCountry].phone}`}
          prefix={<Icon name="phone-ring-solid" size="xs" />}
        >
          {i18n[marketCountry].phone}
        </Button>
        <Button
          variant="tertiary"
          href={`mailto:${i18n[marketCountry].email}`}
          prefix={<Icon name="envelope-solid" size="xs" />}
        >
          {i18n[marketCountry].email}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
