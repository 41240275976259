import * as loan_request from '../loan_request';
import { LoanQuoteStatus } from './LoanQuoteStatus';
import { Broker } from '../broker';
import { Lender } from '../lender';

export enum AcceptedBy {
  MyPage = 'MyPage',
  AutoAccept = 'AutoAccept',
  ACE = 'ACE',
}

export enum Product {
  UnsecuredLoan = 'UnsecuredLoan',
  CreditCard = 'CreditCard',
}

export enum LoanType {
  ConsumerLoan = 'Consumer Loan',
  RefinanceLoan = 'Refinance Loan',
  ComboLoan = 'Combo Loan',
  TopupLoan = 'Topup Loan',
}

export enum LenderProduct {
  DualOffer = 'Dual Offer',
}

type SubLoanQuote = {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  LoanQuoteID: string;
  Duration: number;
  Amount: number;
  RefinanceAmount: number;
  TopupAmount: number;
  TotalPayment: number;
  MonthlyPayment: number;
  StartupFee: number;
  EffectiveRate: number;
  NominalRate: number;
  AdministrationFee: number;
};

export interface LoanQuote {
  ID: string;
  CreatedAt: string | Date;
  DisbursedAt?: string | Date;
  UpdatedAt: string | Date;
  LoanRequestID: string;
  LenderID: string;
  ApplicationID: string;
  Status?: LoanQuoteStatus;
  Amount: number;
  RefinanceAmount?: number;
  TopupAmount?: number;
  CashAmount?: number;
  /**
   * Duration in years
   */
  Duration: number;
  /**
   * DurationMonths in months of year. Between 0 and 11
   */
  DurationMonths?: number;
  TotalPayment: number;
  MonthlyPayment: number;
  StartupFee: number;
  EffectiveRate: number | null;
  NominalRate: number | null;
  AdministrationFee: number;
  LenderReference?: string;
  LenderStatus?: string;
  IsPlaceholderQuote?: boolean;
  SigningLink?: string;
  Product: Product;
  ActivatedAt?: string | Date;
  AcceptedAt?: string | Date;
  AcceptedBy?: AcceptedBy;
  LoanType?: LoanType;
  LenderProduct?: string;
  RequiresDocumentation?: boolean;
  CreditCardInterestFreeDays?: number;
  LenderMaxPossibleAmount?: number;
  SubLoanQuote?: SubLoanQuote;
  Broker?: Broker;
}

export interface BankRequest {
  LenderID: string;
  LoanRequests: loan_request.LoanRequest[];
}

export interface BankQuote extends LoanQuote {
  LenderID: string;
  LoanRequest: LoanRequestTag;
}

export interface LoanRequestTag {
  LoanRequestID: string;
  CreatedAt: string | Date;
  CreatedBy: string;
  Status: loan_request.LoanRequestStatus | undefined;
  Selected?: boolean;
  LenderStatus?: string;
  Amount?: number;
  Duration?: number;
  LenderReference?: string;
}
