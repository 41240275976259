import { motion } from 'motion/react';
import { useMemo } from 'react';
import { useViewStateMachine } from '../../store/useViewStateMachine';
import type { ViewStep } from '../../store/view-state-machine.types';
import { BuyScreen } from '../steps/Buy/BuyScreen';
import { ConfirmationScreen } from '../steps/Confirmation/ConfirmationScreen';
import { IntroScreen } from '../steps/Intro/IntroScreen';
import { PaymentMandateScreen } from '../steps/PaymentMandate/PaymentMandateScreen';
import { SignScreen } from '../steps/Sign/SignScreen';
// import { stepsHeightAnimation } from './WidgetLayout.animation';
import styles from './widgetLayout.module.scss';

/**
 *
 */

const Screens: Record<ViewStep, () => JSX.Element> = {
  INTRO: IntroScreen,
  BUY: BuyScreen,
  SIGN: SignScreen,
  PAYMENT_AUTHORIZE: PaymentMandateScreen,
  CONFIRMATION: ConfirmationScreen,
};

export const WidgetLayoutSteps = () => {
  const { steps, currentStep } = useViewStateMachine();

  // const containerRef = useRef<HTMLDivElement | null>(null);
  // const [screenHeight, setScreenHeight] = useState<number | undefined>();

  // const [prevStep, setPrevStep] = useState(currentStep);
  // const direction = currentStep > prevStep ? 1 : -1;

  // const [containerWidth, setContainerWidth] = useState(0);

  // useEffect(() => {
  // FIXME left/right animation
  // if (containerRef.current)
  //   setContainerWidth(containerRef.current.offsetWidth);
  //
  // setPrevStep(currentStep);
  // }, [currentStep]);

  const Screen: () => JSX.Element = useMemo(
    () => Screens[steps[currentStep] as ViewStep],
    [currentStep]
  );

  // TODO use `motion` native observer to capture if content height changes
  // TODO OR on animation end, set the `height` back to `auto` ← MIGHT BE THE EASIEST / BEST APPROACH

  // useLayoutEffect(() => {
  //   if (containerRef.current) {
  //     // Use requestAnimationFrame for accurate measurement post-DOM update
  //     const handle = requestAnimationFrame(() => {
  //       setScreenHeight(containerRef.current!.clientHeight);
  //     });
  //
  //     return () => cancelAnimationFrame(handle);
  //   }
  // }, [currentStep]);

  return (
    <motion.div
      className={styles.widgetLayoutSteps}
      // variants={stepsHeightAnimation}
      // animate="animate"
      // custom={screenHeight}
    >
      <div
        className={styles.motion}
        key={`step-${currentStep}`}
        // ref={containerRef}
      >
        <Screen key={`step-${currentStep}`} />
      </div>
    </motion.div>
  );
};
