import { ApplicationProgressContext } from '@axo/mypage/feature';
import { ProgressBarWithDescription as UiProgressBarWithDescription } from '@axo/ui-feature/components/ProgressBarWithDescription';
import { ContentLayout } from '@axo/ui-feature/features/MyPageLayout';
import { useContext, useEffect } from 'react';

export const ProgressBarWithDescription = () => {
  const { state: progressBarState } = useContext(ApplicationProgressContext);

  useEffect(() => {
    // console.info('status is in progress ::: ', progressBarState.isInProgress);
  }, [progressBarState.isInProgress]);

  return (
    <ContentLayout.Area area={'progress'}>
      {progressBarState.isInProgress ? (
        <UiProgressBarWithDescription {...progressBarState} />
      ) : null}
    </ContentLayout.Area>
  );
};
