import { FormControl } from '@axo/ui-core/components/form/FormControl';
import type { InputStateVariant } from '@axo/ui-core/components/input';
import { Checkbox } from '@axo/ui-core/components/input/Checkbox';

import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../../config/i18n.config';
import type { Term } from '../../../locales/content.types';
import { useViewStateMachine } from '../../../store/useViewStateMachine';
import type { SalesTerms } from './SalesTerms.types';

import styles from './salesTermsForm.module.scss';
import { SalesTermsSchema as schema } from './schema/SalesTerms.generic.schema';

export const SalesTermsForm = () => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'steps.buy.salesTerms',
  });
  const { updateFormData } = useViewStateMachine();
  const { acceptedTerms } = useViewStateMachine((state) => state.formData);

  const salesTerms = t('terms', {
    returnObjects: true,
  }) as Term[];

  const {
    register,
    getFieldState: _getFieldState,
    formState: { isValid, isValidating },
    setValue,
    getValues,
  } = useForm<SalesTerms>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: salesTerms.reduce<Record<string, boolean>>(
      (acc, term, index) => {
        acc[`${index}_${term.name}`] = false;
        return acc;
      },
      {}
    ),
  });

  const getField = useCallback(
    (name: string) => {
      const state = _getFieldState(name);
      const value = getValues(name);

      return {
        checked: value,
        state: (state.isDirty && !state.invalid
          ? 'success'
          : 'neutral') as InputStateVariant,
      };
    },
    [_getFieldState, getValues]
  );

  const toggleParentValue = useCallback(
    (name: string) => {
      const currentValue = getValues(name);
      setValue(name, !currentValue, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue, getValues]
  );

  useEffect(() => {
    if (isValid !== acceptedTerms) updateFormData({ acceptedTerms: isValid });
  }, [isValid, acceptedTerms]);

  useEffect(() => {
    // If `acceptedTerms` is true during mount/rehydration, set all fields to true
    if (acceptedTerms)
      Object.keys(getValues()).forEach((field) => {
        setValue(field, true, { shouldValidate: true, shouldDirty: true });
      });
  }, [acceptedTerms, getValues, setValue]);

  const renderField = useCallback(
    (term: Term, index: number) => {
      const fieldName = `${index}_${term.name}`;
      const { state, checked } = getField(fieldName);
      const { ref, ...inputProps } = register(fieldName);

      return (
        <FormControl key={fieldName} className={styles.term}>
          <FormControl.Label htmlFor={fieldName} className={styles.label}>
            <span dangerouslySetInnerHTML={{ __html: term.label }} />
          </FormControl.Label>
          <Checkbox
            {...inputProps}
            _ref={ref}
            state={state}
            checked={checked}
          />

          {term.sub ? (
            <FormControl.Caption>
              <Stack className={styles.subterms}>
                {term.sub.map((subterm, subindex) => (
                  <FormControl
                    key={`${subindex}-${subterm.name}`}
                    className={styles.subterm}
                  >
                    <FormControl.Label size="small" htmlFor={subterm.name}>
                      {subterm.label}
                    </FormControl.Label>
                    <Checkbox
                      size={'s'}
                      name={subterm.name}
                      state={state}
                      checked={checked}
                      onChange={() => toggleParentValue(fieldName)}
                    />
                  </FormControl>
                ))}
              </Stack>
            </FormControl.Caption>
          ) : null}
        </FormControl>
      );
    },
    [getField, register, isValidating, acceptedTerms]
  );

  return (
    <Stack className={styles.salesTermsForm}>
      <Text size="m">
        <b>{t('intro')}</b>
      </Text>
      <form className={styles.terms}>{salesTerms.map(renderField)}</form>
    </Stack>
  );
};
