import { FormControl } from '@axo/ui-core/components/form/FormControl';
import type { InputStateVariant } from '@axo/ui-core/components/input';
import { Checkbox } from '@axo/ui-core/components/input/Checkbox';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../../config/i18n.config';
import type { Term } from '../../../locales/content.types';
import { useViewStateMachine } from '../../../store/useViewStateMachine';
import { SalesTermsSchema as schema } from '../Buy/schema/SalesTerms.generic.schema';
import styles from './signInsuranceForm.module.scss';
import type { SignTerm } from './SignInsuranceTerm.types';

export const SignInsuranceForm = () => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'steps.sign.signInsurance',
  });
  const { updateFormData } = useViewStateMachine();
  const { hasSigned } = useViewStateMachine((state) => state.formData);

  const signTerm = t('term', {
    returnObjects: true,
  }) as Term;

  const {
    register,
    getFieldState: _getFieldState,
    formState: { isValid, isValidating },
    getValues,
    setValue,
  } = useForm<SignTerm>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      [signTerm.name]: false,
    },
  });

  const getField = useCallback(
    (name: string) => {
      const state = _getFieldState(name);
      const value = getValues(name);

      return {
        checked: value,
        state: (state.isDirty && !state.invalid
          ? 'success'
          : 'neutral') as InputStateVariant,
      };
    },
    [_getFieldState, getValues]
  );

  useEffect(() => {
    if (isValid !== hasSigned) updateFormData({ hasSigned: isValid });
  }, [isValid, hasSigned]);

  useEffect(() => {
    // If `hasSigned` is true during mount/rehydration, set all fields to true
    if (hasSigned)
      Object.keys(getValues()).forEach((field) => {
        setValue(field, true, { shouldValidate: true, shouldDirty: true });
      });
  }, [hasSigned, getValues, setValue]);

  const { ref, ...inputProps } = register(signTerm.name);

  return (
    <Stack className={styles.signInsuranceForm}>
      <Text size="m">
        <b>{t('intro')}</b>
      </Text>
      <form className={styles.term}>
        <FormControl key={signTerm.name} className={styles.term}>
          <FormControl.Label htmlFor={signTerm.name} className={styles.label}>
            <span dangerouslySetInnerHTML={{ __html: signTerm.label }} />
          </FormControl.Label>
          <Checkbox
            {...inputProps}
            _ref={ref}
            state={getField(signTerm.name).state}
            checked={getField(signTerm.name).checked}
          />
        </FormControl>
      </form>
      <Text size="s" className={styles.note}>
        {t('note')}
      </Text>
    </Stack>
  );
};
