import { useEffect } from 'react';
import type { InsurancePaymentMandateType } from '../../config/payment-mandate.types';
import { getUpdateRequestState } from '../../store/data-store.utils';
import { useDataStore } from '../../store/useDataStore';
import {
  isInsurancePaymentMandateDataComplete,
  createPaymentMandateDto,
  useCreatePaymentMandateApiImpl,
} from './useCreateInsurancePaymentMandateApi.factory';
import { useInsurancePaymentMandateApiImpl } from './useInsurancePaymentMandateApi.factory';

type UseCreateInsurancePaymentMandateProps = {
  paymentMandateType: InsurancePaymentMandateType;
};

/**
 * initial request to our back-end to create the data to send to (and sign by) payment provider
 * aka. not required if creating the final payment mandate / authorization is a one-shot
 *
 * async creation process
 * `create` → `get` w/ polling till conditions are met
 * react-query hook does the polling / refetch
 */

export const useCreateInsurancePaymentMandate = ({
  paymentMandateType,
}: UseCreateInsurancePaymentMandateProps) => {
  const { appUri } = useDataStore();
  const { insurancePaymentMandate, insurance, input } = useDataStore(
    (state) => state.application
  );
  const { updateRequestState } = getUpdateRequestState(
    'insurancePaymentMandate'
  );

  /** create */

  const useCreateInsurancePaymentMandateApi =
    useCreatePaymentMandateApiImpl(paymentMandateType);

  const {
    mutateAsync: createInsurancePaymentMandate,
    data: createInsurancePaymentMandateData,
    ...createInsurancePaymentMandateResult
  } = useCreateInsurancePaymentMandateApi();

  const createPaymentMandate = async () => {
    updateRequestState({ status: 'loading' });

    const paymentMandateDto = createPaymentMandateDto(paymentMandateType, {
      InsuranceID: insurance.data?.ID,
      CustomerID: insurance.data?.CustomerID,
      PersonID: insurance.data?.PersonID,
      MarketCountry: insurance.data?.MarketCountry,
      ...input,
      appUri,
    });

    await createInsurancePaymentMandate(paymentMandateDto as never);
  };

  useEffect(() => {
    if (insurancePaymentMandate.status === 'requested') createPaymentMandate();
  }, [insurancePaymentMandate.status]);

  /** get */

  const useInsurancePaymentMandateApi =
    useInsurancePaymentMandateApiImpl(paymentMandateType);

  const {
    data: insurancePaymentMandateData,
    ...insurancePaymentMandateResult
  } = useInsurancePaymentMandateApi(
    createInsurancePaymentMandateData?.ID,
    false
  );

  useEffect(() => {
    if (
      insurancePaymentMandateData &&
      isInsurancePaymentMandateDataComplete(
        paymentMandateType,
        insurancePaymentMandateData
      )
    )
      updateRequestState({
        status: 'completed',
        data: insurancePaymentMandateData,
      });
  }, [insurancePaymentMandateResult.isSuccess, insurancePaymentMandateData]);
};
