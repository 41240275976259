import { ConfigService } from '../config/ConfigService';
import { defaultSteps } from './view-state-machine.config';
import type {
  ViewStateMachineState,
  ViewStep,
} from './view-state-machine.types';

export const isStepValid = ({
  currentStep,
  formData,
  isEnabled,
}: ViewStateMachineState): boolean => {
  const currentStepName: ViewStep = defaultSteps[currentStep];

  const isAccountNumberRequired =
    ConfigService.config.options?.hasAccountNumberForm ?? false;
  const hasSignInsuranceForm =
    ConfigService.config.options?.hasSignInsuranceForm ?? false;

  switch (currentStepName) {
    case 'INTRO':
      return isEnabled;
    case 'BUY':
      return formData.insuredAmount > 0 && formData.acceptedTerms;
    case 'SIGN':
      return !hasSignInsuranceForm
        ? !isAccountNumberRequired || formData.hasBankAccount
        : isAccountNumberRequired
        ? formData.hasBankAccount && formData.hasSigned
        : formData.hasSigned;

    case 'PAYMENT_AUTHORIZE':
      return formData.hasPaymentMandate;
    default:
      return false;
  }
};
