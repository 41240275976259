import type {
  IAuthProviderConfig,
  IAuthProviderResponse,
} from '@axo/shared/auth/providers';
import { useCallback } from 'react';
import { getUpdateRequestState } from '../../../../store/data-store.utils';
import { useDataStore } from '../../../../store/useDataStore';
import type { PaymentProviderProps } from '../../PaymentProvider.types';
import type { PaymentData } from '../bankid/bank-id-payment.types';

type UseCriiptoPaymentProviderProps = {
  data?: PaymentData;
} & PaymentProviderProps;

type UseCriiptoPaymentProvider = {
  onError: IAuthProviderConfig['onSuccess'];
  onSuccess: IAuthProviderConfig['onError'];
};

export const useCriiptoPaymentProvider = ({
  data,
  ...callbacks
}: UseCriiptoPaymentProviderProps = {}): UseCriiptoPaymentProvider => {
  const setInput = useDataStore((state) => state.setInput);
  const inputData = useDataStore((state) => state.application.input);
  const { updateRequestState } = getUpdateRequestState(
    'insurancePaymentMandateComplete'
  );

  const onSuccess = useCallback(
    ({ token, personID }: IAuthProviderResponse) => {
      if (!token) callbacks.onCancel?.();

      setInput({
        ...inputData,
        paymentMandateProviderResponse: { token, personID },
      });

      updateRequestState({ status: 'requested', data: { token, personID } });

      callbacks.onSuccess?.();
    },
    [callbacks]
  );

  const onError = useCallback(() => {
    callbacks.onError?.();
  }, [callbacks]);

  return {
    onSuccess,
    onError,
  };
};
