import { Modal } from '@axo/ui-core/components/Modal';
import styles from './AcceptModal.module.scss';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Heading, Text } from '@axo/ui-core/components/typography';

type AcceptModalProps = {
  open: boolean;
  onClose?: () => void;
  title?: string;
  children: ReactNode;
  actionBar?: {
    title?: ReactNode;
    buttons: ReactNode;
  };
  classes?: {
    root?: string;
    content?: string;
    actionBar?: {
      root?: string;
      buttons?: string;
    };
  };
  type?: 'default' | 'info' | 'success' | 'attention' | 'warning';
};

/**
 * @deprecated Useless component. Use ui-feature Modal directly instead
 */
export function AcceptModal({
  open,
  onClose,
  title,
  actionBar,
  children,
  classes,
  type = 'default',
  ...props
}: AcceptModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={clsx(styles.modalRoot, classes?.root)}
      data-modal-type={type}
      {...props}
    >
      <Modal.Header>
        {title && (
          <Modal.Title>
            <Heading size="s">{title}</Heading>
          </Modal.Title>
        )}
      </Modal.Header>
      <Stack gap={'xs'} className={clsx(styles.content, classes?.content)}>
        {children}
      </Stack>
      {actionBar && (
        <Modal.Actions className={styles.actions}>
          <Stack
            gap={'xs'}
            className={clsx(styles.actionContainer, classes?.actionBar?.root)}
          >
            {actionBar.title && <Text>{actionBar.title}</Text>}
            <Stack
              gap={'3xs'}
              className={clsx(styles.buttons, classes?.actionBar?.buttons)}
            >
              {actionBar.buttons}
            </Stack>
          </Stack>
        </Modal.Actions>
      )}
    </Modal>
  );
}
