import {
  createInsurancePolicy,
  CreateInsurancePolicyParams,
} from '@axo/shared/data-access/api/insurance';
import { useAPI } from '@axo/shared/data-access/provider';
import { useMutation } from 'react-query';

export function useInsurancePolicyCreate() {
  const api = useAPI();

  return useMutation((params: CreateInsurancePolicyParams) =>
    createInsurancePolicy(api)(params)
  );
}
