import { insurance } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

/**
 * @deprecated use v2 instead
 */
export function getInsurancePersonIsEligible({
  url: { service },
  token,
}: IAPI) {
  return async function getInsurancePersonIsEligible(
    params: insurance.InsurancePersonIsEligibleParams
  ) {
    const url = new URL(`/insurance/person/is-eligible`, service);
    Object.entries(params).forEach(([key, value]) =>
      url.searchParams.set(key, value)
    );

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (!response.ok)
      throw new Error(`Network response was not OK: ${response.status}`);

    return (await response.json()) as insurance.InsurancePersonIsEligible;
  };
}
