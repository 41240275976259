import { IAPI } from '../lib/models/api';
import { interpolateUri } from './api-crud.utils';

type QueryParams = Record<string, string | number>;

export const createResourceRequest = <
  TRequestBody = unknown,
  TResponse = unknown,
  TRequestParams extends QueryParams = QueryParams
>(
  resourceUri: string
) => {
  return function createRequest({ url: { service: baseURL }, token }: IAPI) {
    return async function create(
      body: TRequestBody,
      params?: TRequestParams
    ): Promise<TResponse> {
      const url = new URL(interpolateUri(resourceUri, params), baseURL);

      if (!token) {
        throw new Error(`Missing token`);
      }

      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.status}`);
      }

      return (await response.json()) as TResponse;
    };
  };
};

export const listResourceRequest = <
  TRequestQuery extends QueryParams = QueryParams,
  TResponse = unknown,
  TRequestParams extends QueryParams = QueryParams
>(
  resourceUri: string
) => {
  return function listRequest({ url: { service: baseURL }, token }: IAPI) {
    return async function list(
      query: TRequestQuery,
      params?: TRequestParams
    ): Promise<TResponse> {
      const url = new URL(interpolateUri(resourceUri, params), baseURL);

      Object.entries(query).forEach(([key, value]) => {
        url.searchParams.set(key, value.toString());
      });

      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.status}`);
      }

      return (await response.json()) as TResponse;
    };
  };
};

export const getResourceRequest = <
  TResponse = unknown,
  TRequestParams extends QueryParams = QueryParams
>(
  resourceUri: string
) => {
  return function getRequest({ url: { service: baseURL }, token }: IAPI) {
    return async function get(params: TRequestParams): Promise<TResponse> {
      const url = new URL(interpolateUri(resourceUri, params), baseURL);

      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.status}`);
      }

      return (await response.json()) as TResponse;
    };
  };
};

export const updateResourceRequest = <
  TRequestBody = unknown,
  TResponse = unknown,
  TRequestParams extends QueryParams = QueryParams
>(
  resourceUri: string
) => {
  return function updateRequest({ url: { service: baseURL }, token }: IAPI) {
    return async function update(
      body: TRequestBody,
      params: TRequestParams
    ): Promise<TResponse> {
      const url = new URL(interpolateUri(resourceUri, params), baseURL);

      const response = await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.status}`);
      }

      return (await response.json()) as TResponse;
    };
  };
};

export const patchResourceRequest = <
  TRequestBody = unknown,
  TResponse = unknown,
  TRequestParams extends QueryParams = QueryParams
>(
  resourceUri: string
) => {
  return function patchRequest({ url: { service: baseURL }, token }: IAPI) {
    return async function patch(
      body: TRequestBody,
      params: TRequestParams
    ): Promise<TResponse> {
      const url = new URL(interpolateUri(resourceUri, params), baseURL);

      const response = await fetch(url, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.status}`);
      }

      return (await response.json()) as TResponse;
    };
  };
};

export const deleteResourceRequest = <
  TResponse = unknown,
  TRequestParams extends QueryParams = QueryParams
>(
  resourceUri: string
) => {
  return function deleteRequest({ url: { service: baseURL }, token }: IAPI) {
    return async function remove(params: TRequestParams): Promise<TResponse> {
      const url = new URL(interpolateUri(resourceUri, params), baseURL);

      const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.status}`);
      }

      return (await response.json()) as TResponse;
    };
  };
};
