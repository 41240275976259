import { MyPageFlags } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  useLoanApplication,
  useLoanApplicationFromCustomer,
  useLoanApplicationFromPerson,
  useLoanQuotePresentation,
} from '@axo/shared/data-access/hooks';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import {
  loan_quote_accept,
  Product,
  StatusGroup,
} from '@axo/shared/data-access/types';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useFlags, useTypedFlags } from '@axo/shared/services/feature-flags';
import { features } from '@axo/shared/services/feature-flags/config/features.config';
import { usePuzzelChat } from '@axo/shared/services/puzzel-chat';
import { Icons } from '@axo/shared/ui/atoms';
import { Heading } from '@axo/ui-core/components/typography';
import { HeaderInfo } from '@axo/ui-feature/components/HeaderInfo/HeaderInfo';
import { clsx } from 'clsx';
import {
  ComponentType,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ApplicationStatusContext } from '../../ApplicationStatusProvider';
import { useAuthProvider } from '../../AuthProvider/useAuthProvider';
import { ContactModal } from '@axo/ui-feature/components/ContactModal';
import { InsuranceProductWidget } from '../../InsuranceProductWidget/InsuranceProductWidget';
import { LoanQuoteFooter } from '../../LoanQuoteFooter/LoanQuoteFooter';
import { LoanQuotePresentation } from '../../LoanQuotePresentation';
import { useLocaleContext } from '../../LocaleProvider';
import { NoApplication } from '../../NoApplication';
import styles from './LoanQuoteList.module.scss';
import { QuoteTabs } from './QuoteTabs';
import { useFindDefaultTab } from './useFindDefaultTab';
import { useLatestActiveApplication } from './useLatestActiveApplication';
import { useQuoteViewLogger } from './useQuoteViewLogger';
import {
  ContentLayout,
  ContentLayoutProps,
} from '@axo/ui-feature/features/MyPageLayout';

const APP_FORM_URL = import.meta.env.VITE_APP_FORM_URL;

export function LoanQuoteList({
  AcceptOfferModalWithInsurance,
  hasTabs,
  hasNextSteps,
}: {
  AcceptOfferModalWithInsurance: ComponentType<loan_quote_accept.TAcceptOfferModal>;
  hasTabs: boolean;
  hasNextSteps: boolean;
}) {
  const { flag_show_puzzel_chat } = useTypedFlags(MyPageFlags);
  const {
    'show-my-page-communication-elements': flag_show_communication_elements,
  } = useFlags(MyPageFlags);
  const { t } = useTranslation();

  const {
    state: { application },
  } = useContext(LoanApplicationContext);
  const {
    state: {
      user: { customerID, personID },
    },
  } = useContext(DataAccessContext);

  const { isLoggedInAsUnverified } = useAuthProvider();

  const { state: headerInfoState } = useContext(ApplicationStatusContext);
  const { marketCountry } = useLocaleContext();
  const { updateChatVariables } = usePuzzelChat({
    country: marketCountry.toLowerCase() as 'no' | 'fi' | 'se' | 'dk',
    active: flag_show_puzzel_chat,
    delay: 15000,
  });

  /**
   * requested application
   *
   * direct access : the application id from `?id=...`
   * magic access : the application id linked to the magic token
   * bankId access : ???
   *
   * @note: afaik. the `useFindDefaultTab` neglects this
   * when the requested application is closed or no offers (f.ex. cc) and there's another active application (f.ex. loan),
   * then it'll show the loan application (which makes little sense to request A and get B without explanation)
   *
   * atm. this magic token behaviour is un-altered, we need to map the intended behaviour first
   *
   *
   */
  const requestedApplicationID = application?.ID ?? '';
  const currentApplication = useLoanApplication(requestedApplicationID);
  const currentAppProduct = currentApplication.data?.Product ?? null;
  const requestedApplication = currentApplication.data;
  const loanQuotePresentation = useLoanQuotePresentation(
    requestedApplicationID
  );
  const loanApplicationsByCustomer = useLoanApplicationFromCustomer(
    customerID,
    { offset: 0, limit: 25 },
    [StatusGroup.Active]
  );
  const loanApplicationsByPerson = useLoanApplicationFromPerson(
    personID,
    { offset: 0, limit: 25 },
    [StatusGroup.Active]
  );

  const loanApplications = requestedApplicationID
    ? loanApplicationsByCustomer
    : loanApplicationsByPerson;
  const { creditCardApplication, unsecuredLoanApplication } =
    useLatestActiveApplication({
      requestedApplication,
      loanApplications: loanApplications.data ?? [],
    });

  const [showContactModal, setShowContactModal] = useState(false);
  const [activeProduct, setActiveProduct] = useState<Product | undefined>();
  const { isLoading } = useFindDefaultTab({
    isLoading: loanApplications.isLoading,
    unsecuredLoanApplication,
    creditCardApplication,
    setTab: setActiveProduct,
    requestedProduct: requestedApplication?.Product,
  });

  const showTabs =
    hasTabs &&
    !!unsecuredLoanApplication &&
    !!creditCardApplication &&
    !isLoggedInAsUnverified; // no tabs for direct access

  const { logQuoteView } = useQuoteViewLogger(
    loanQuotePresentation.data,
    creditCardApplication,
    unsecuredLoanApplication
  );

  const logApplicationQuoteView = useCallback(
    async (product: Product) => {
      await logQuoteView(product);
    },
    [logQuoteView]
  );

  function handleProductChange(product: Product) {
    setActiveProduct(product);
    logApplicationQuoteView(product);
  }

  useEffect(() => {
    if (currentAppProduct) {
      logApplicationQuoteView(currentAppProduct);
    }
  }, [currentAppProduct, logApplicationQuoteView]);

  const getActiveApplication =
    activeProduct === Product.CreditCard
      ? creditCardApplication
      : unsecuredLoanApplication;

  const applicationShown = requestedApplicationID
    ? requestedApplicationID
    : getActiveApplication?.ID ?? '';

  updateChatVariables({ applicationID: getActiveApplication?.ID });

  let { 'has-mandatory-insurance-on-mypage': hasMandatoryInsurance } =
    useFlags(features);
  hasMandatoryInsurance ??= false;

  const layoutSequence: ContentLayoutProps['sequence'] = useMemo(
    () =>
      isLoggedInAsUnverified && hasMandatoryInsurance
        ? 'insurance-mandatory'
        : 'static',
    [isLoggedInAsUnverified, hasMandatoryInsurance]
  );

  return (
    <ContentLayout
      sequence={layoutSequence}
      className={clsx(styles.container, 'LoanQuoteList')}
    >
      <ContentLayout.Area area={'header'} className={styles.header}>
        {flag_show_communication_elements ? (
          <HeaderInfo
            onContactButtonClick={() => setShowContactModal(true)}
            {...headerInfoState}
          />
        ) : (
          <Heading size="l" className={styles.title}>
            {t('Welcome!')}
          </Heading>
        )}
        <ContactModal
          // FIXME the ContactModal is embed here and on `Main` ?
          isOpen={showContactModal}
          onClose={() => setShowContactModal(false)}
          marketCountry={marketCountry}
        />
      </ContentLayout.Area>

      {!loanApplications.isLoading &&
      !isLoading &&
      activeProduct !== undefined ? (
        showTabs ? (
          <QuoteTabs
            hasNextSteps={hasNextSteps}
            unsecuredLoanApplication={unsecuredLoanApplication}
            creditCardApplication={creditCardApplication}
            activeProduct={activeProduct}
            handleActiveProduct={handleProductChange}
            AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
          />
        ) : applicationShown === '' ? (
          <NoApplication
            icon={<Icons.MoneyNotes size="lg" />}
            title={t('You have not applied for any loans')}
            description={t('Complete our form now and start receiving offers')}
            buttonLabel={t('Apply now')}
            action={() => (window.location.href = APP_FORM_URL)}
          />
        ) : (
          <LoanQuotePresentation
            applicationID={applicationShown}
            AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
          />
        )
      ) : null}

      <ContentLayout.Area area={'insurance'}>
        <InsuranceProductWidget applicationId={getActiveApplication?.ID} />
      </ContentLayout.Area>

      <ContentLayout.Area area={'footer'}>
        <LoanQuoteFooter
          applicationId={getActiveApplication?.HumanReadableID ?? undefined}
        />
      </ContentLayout.Area>
    </ContentLayout>
  );
}
